import { CloseOutlined } from "@ant-design/icons";
import { CircularProgress, Dialog } from "@material-ui/core";
import React from "react";

import styles from "./selectProject.module.scss";

import _ from "lodash";
import { DupLoginContextProps } from "../../contexts/DupLoginContext";
import { Project } from "../../config/models";
import { api } from "../../config/api";
import SelectProjectOriginModal from "../../components/modal/selectProject/selectProjectOrigin/SelectProjectOriginModal";
import EditProjectModal from "../../components/modal/editProject/EditProject";
import SimpleConfirm from "../../components/modal/confirm/SimpleConfirm";
import DeleteConfirm from "../../components/modal/deleteConfirm/DeleteConfirm";
import { pages } from "../../constants/PagePaths";
import AlertContext from "../../contexts/AlertContext";
import { Link, Redirect } from "react-router-dom";

interface appProps {
  dupLogin: DupLoginContextProps;
}
const tab: string[] = ["normal", "contract", "change", "ready", "complete"];
const copyTab: string[] = ["copy", "contract", "change", "ready", "complete"];

interface appState {
  type: "copy" | "normal" | "contract" | "change" | "ready" | "complete";
  projects: Array<Project>;
  removeConfirmOpen: boolean;

  removeNormalConfirmOpen: boolean;
  createConfirmOpen: boolean;
  loading: boolean;
  editOpen: boolean;
  selectedProject: Project;
  selectOriginOpen: boolean;
  isExpired: boolean;
  copy: boolean;
  isProjectDeleted: boolean;
  tab: string[];
}

export default class CostProjectsDetailPage extends React.Component<
  appProps,
  appState
> {
  projects: Array<Project> = [];
  constructor(props: any) {
    super(props);
    this.state = {
      type: "normal",
      projects: [],
      removeConfirmOpen: false,
      removeNormalConfirmOpen: false,
      createConfirmOpen: false,
      loading: false,
      editOpen: false,
      selectedProject: undefined!,
      selectOriginOpen: false,
      isExpired: false,
      copy: false,
      isProjectDeleted: false,
      tab: tab,
    };
  }

  selectType = (
    type: "copy" | "normal" | "contract" | "change" | "ready" | "complete"
  ) => {
    const ps = this.projects?.filter((p: Project) => p.type === type);
    this.setState({
      type,
      projects: ps,
    });
  };

  async componentDidMount() {
    const windowLocation = window.location.href;
    const paths = windowLocation.split("/");
    let lastpath = paths[paths.length - 1];
    const id = lastpath.split("?")[0];
    const type = lastpath.split("?")[1].split("=")[1];
    this.setState({
      copy: type == "copy",
      tab: type == "copy" ? copyTab : tab,
    });
    const projects = await api.getRelativeProjects(parseInt(id));
    this.projects = projects;
    const info = await this.props.dupLogin.checkDupLogin(api.getMyInfo);
    // console.log(info);
    if (
      info?.data?.expiry_date?.toString() === "None" ||
      new Date(info?.data?.expiry_date).getTime() -
      new Date(new Date().format("yyyy-MM-dd")).getTime() <
      0
    ) {
      // console.log("ddd");
      this.setState({ isExpired: true });
    }

    const ps = this.projects?.filter(
      (p: Project) => p.type === "normal" || p.type === "copy"
    );
    this.setState({
      projects: ps,
    });
  }

  clickRow = (id: any) => {
    const url = `${process.env.REACT_APP_COST_URL}document/${window.btoa(id)}`;
    // window.open(url)
    window.location.href = url;
  };

  clickEdit = (event: any, project: Project) => {
    event.stopPropagation();
    event.preventDefault();
    const p = _.cloneDeep(project);
    this.setState({
      selectedProject: p,
      editOpen: true,
    });
  };

  clickRemove = (event: any, project: Project) => {
    event.stopPropagation();
    event.preventDefault();
    if (project.type === "normal") {
      this.setState({
        removeNormalConfirmOpen: true,
        selectedProject: project,
      });
    } else {
      this.setState({
        removeConfirmOpen: true,
        selectedProject: project,
      });
    }
  };

  onClickRemoveCancel = () => {
    this.setState({
      removeConfirmOpen: false,
      removeNormalConfirmOpen: false,
    });
  };

  onClickRemoveConfirm = async () => {
    this.setState({
      removeConfirmOpen: false,
      loading: true,
    });
    const project = this.state.selectedProject;
    if (project) {
      const res = await this.props.dupLogin.checkDupLogin(() =>
        api.deleteProject(project.id)
      );
      if (project.type === "normal") {
        // this.onClose();
      } else {
        await this.componentDidMount();
        const ps = this.projects?.filter(
          (p: Project) => p.type === this.state.type
        );
        this.setState({ loading: false, projects: ps }, () => {
          if (res?.data?.code === 1) {
            this.context.showAlert(res?.data?.message);
            return;
          }
        });
      }
    }
    this.setState({
      removeConfirmOpen: false,
      loading: false,
      isProjectDeleted: true,
    });
  };
  onClickCreateCancel = () => {
    this.setState({
      createConfirmOpen: false,
    });
  };

  onClickCreateConfirm = () => {
    this.setState({
      createConfirmOpen: false,
      selectOriginOpen: true,
      // loading: true
    });
    // const normalProject = this.projects.filter((project: Project) => project.type === 'normal')[0];
    // this.makeOtherProject(this.state.type, normalProject.id)
  };

  onEditClose = async (project?: Project) => {
    this.setState({
      editOpen: false,
    });
    if (project) {
      this.setState({ loading: true });
      await this.props.dupLogin.checkDupLogin(() =>
        api.editProjectInfo(project)
      );
      const i = this.projects?.findIndex((p: Project) => p.id === project.id);
      this.projects[i] = _.cloneDeep(project);
      const ps = this.projects?.filter(
        (p: Project) => p.type === this.state.type
      );
      this.setState({ loading: false, projects: ps });
    }
  };

  onOriginClose = (args?: { projectId: number; originType: string }) => {
    const projectId = args?.projectId;
    const originType = args?.originType;
    this.setState({
      selectOriginOpen: false,
      loading: projectId ? true : false,
    });
    if (projectId && originType) {
      this.makeOtherProject(this.state.type, projectId, originType);
    }
  };

  getProjectTypeText = (
    type: "copy" | "normal" | "contract" | "change" | "ready" | "complete"
  ) => {
    return type === "normal" || type === "copy"
      ? "일반"
      : type === "contract"
        ? "계약"
        : type === "change"
          ? "설계변경"
          : type === "ready"
            ? "기성"
            : "준공";
  };

  async makeOtherProject(
    kind: "change" | "ready" | "contract" | "complete" | "normal" | "copy",
    id: number,
    originType: string
  ) {
    const originId = this.projects?.filter(
      (project: Project) => project.type === "normal" || project.type === "copy"
    )[0].id;
    if (kind === "change") {
      this.setState({
        loading: true,
      });
      const type = "change";
      const createRes = await this.props.dupLogin.checkDupLogin(() =>
        api.createEtcProject({ id, type, originId })
      );
      const createCode = createRes?.data.code;
      if (createCode === 0) {
        this.context.showAlert(createRes?.data.message);
        // this.setState({
        //   loading: false,
        // });
        return;
      }
      const projectId = createRes?.data.id;
      const res = await this.props.dupLogin.checkDupLogin(() =>
        api.getProjectInfo({ projectId })
      );
      const code = res?.data?.code;
      if (!code) {
        alert("프로젝트가 없습니다.");
        return;
      }
      const project = res?.data.project;
      await this.cloneProjectEtc(project, projectId, "change");

      this.setState({
        loading: false,
      });
      this.context.showAlert("완료되었습니다.");
      await this.componentDidMount();
      const ps = this.projects?.filter(
        (p: Project) => p.type === this.state.type
      );
      this.setState({ loading: false, projects: ps });
    } else if (kind === "ready") {
      this.setState({
        loading: true,
      });
      const type = "ready";
      const createRes = await this.props.dupLogin.checkDupLogin(() =>
        api.createEtcProject({ id, type, originId })
      );
      const createCode = createRes?.data.code;
      if (createCode === 0) {
        this.context.showAlert(createRes?.data.message);
        this.setState({
          loading: false,
        });
        return;
      }
      const projectId = createRes?.data.id;
      const res = await this.props.dupLogin.checkDupLogin(() =>
        api.getProjectInfo({ projectId })
      );
      const code = res?.data.code;
      if (!code) {
        alert("프로젝트가 없습니다.");
        return;
      }
      const project = res?.data.project;
      await this.cloneProjectEtc(project, projectId, "ready");
      this.setState({
        loading: false,
      });
      this.context.showAlert("완료되었습니다.");
      await this.componentDidMount();
      const ps = this.projects?.filter(
        (p: Project) => p.type === this.state.type
      );
      this.setState({ loading: false, projects: ps });
    } else if (kind === "contract") {
      this.setState({
        loading: true,
      });
      const type = "contract";
      const createRes = await this.props.dupLogin.checkDupLogin(() =>
        api.createEtcProject({ id, type, originId })
      );
      const createCode = createRes?.data.code;
      if (createCode === 0) {
        this.context.showAlert(createRes?.data.message);
        this.setState({
          loading: false,
        });
        return;
      }
      const projectId = createRes?.data.id;
      const res = await api.getProjectInfo({ projectId });
      const code = res?.data.code;
      if (!code) {
        alert("프로젝트가 없습니다.");
        return;
      }
      const project = res?.data.project;
      await this.cloneProjectEtc(project, projectId, "contract", originType);

      this.setState({
        loading: false,
      });
      this.context.showAlert("완료되었습니다.");
      await this.componentDidMount();
      const ps = this.projects?.filter(
        (p: Project) => p.type === this.state.type
      );
      this.setState({ loading: false, projects: ps });
    } else if (kind === "complete") {
      this.setState({
        loading: false,
      });
      const type = "complete";
      const createRes = await this.props.dupLogin.checkDupLogin(() =>
        api.createEtcProject({ id, type, originId })
      );
      const createCode = createRes?.data.code;
      if (createCode === 0) {
        this.context.showAlert(createRes?.data.message);
        this.setState({
          loading: false,
        });
        return;
      }
      const projectId = createRes?.data.id;
      const res = await this.props.dupLogin.checkDupLogin(() =>
        api.getProjectInfo({ projectId })
      );
      const code = res?.data.code;
      if (!code) {
        alert("프로젝트가 없습니다.");
        return;
      }
      const project = res?.data.project;
      await this.cloneProjectEtc(project, projectId, "complete");

      this.setState({
        loading: false,
      });
      this.context.showAlert("완료되었습니다.");
      await this.componentDidMount();
      const ps = this.projects?.filter(
        (p: Project) => p.type === this.state.type
      );
      this.setState({ loading: false, projects: ps });
    }
  }

  async cloneProjectEtc(
    project: any,
    id: number,
    kind: string,
    originType?: string
  ) {
    const projectStatements = project.statements;
    for (const corporation of projectStatements) {
      this.reculsiveCloneDetail(corporation, kind, originType);
    }
    if (project.etc) {
      const etc = project.etc;
      if (etc.waste) {
        if (kind === "change" || kind === "complete") {
          const wasteCopy = _.cloneDeep(etc.waste);
          for (const [workIndex, work] of (
            etc.waste.children || []
          ).entries()) {
            for (const item of work.detail) {
              item.origin = true;
              item.newInChange = false;
            }
            for (const [index, item] of wasteCopy.children[
              workIndex
            ].detail.entries()) {
              item.origin = false;
              item.newInChange = false;
              work.detail.splice(index * 2 + 1, 0, item);
            }
          }
          for (const item of etc.waste.items) {
            item.origin = true;
            item.newInChange = false;
          }
          for (const [index, item] of wasteCopy.items.entries()) {
            item.origin = false;
            item.newInChange = false;
            etc.waste.items.splice(index * 2 + 1, 0, item);
          }
        } else if (kind === "ready") {
          for (const work of etc.waste.children) {
            for (const [index, item] of work.detail.entries()) {
              item.ready_count = [0];
              item.ready_formula = [0];
              item.ready_acc_count = 0;
            }
          }
          for (const item of etc.waste.items) {
            item.ready_count = [0];
            item.ready_formula = [0];
            item.ready_acc_count = 0;
          }
        } else if (kind === "contract") {
          if (originType === "change") {
            for (const work of etc.waste.children) {
              for (const [index, item] of work.detail.entries()) {
                if (item.origin) {
                  work.detail.splice(index, 1);
                }
              }
              for (const item of work.detail.entries()) {
                item.origin = true;
                item.applyPercent = true;
              }
            }
            for (const [index, item] of etc.waste.items.entries()) {
              if (item.origin) {
                etc.waste.items.splice(index, 1);
              }
            }
            for (const item of etc.waste.items.entries()) {
              item.applyPercent = true;
              item.origin = true;
            }
          } else {
            for (const [index, work] of (etc.waste.children ?? []).entries()) {
              for (const item of work.detail.entries()) {
                item.applyPercent = true;
              }
            }
            for (const item of etc.waste.items) {
              item.applyPercent = true;
            }
          }
        }
      }
      if (etc.procurement) {
        if (kind === "change" || kind === "complete") {
          const wasteCopy = _.cloneDeep(etc.procurement);
          for (const [workIndex, work] of (
            etc.procurement.children || []
          ).entries()) {
            for (const item of work.detail) {
              item.origin = true;
              item.newInChange = false;
            }
            for (const [index, item] of wasteCopy.children[
              workIndex
            ].detail.entries()) {
              item.origin = false;
              item.newInChange = false;
              work.detail.splice(index * 2 + 1, 0, item);
            }
          }
          for (const item of etc.procurement.items) {
            item.origin = true;
            item.newInChange = false;
          }
          for (const [index, item] of wasteCopy.items.entries()) {
            item.origin = false;
            item.newInChange = false;
            etc.procurement.items.splice(index * 2 + 1, 0, item);
          }
        } else if (kind === "ready") {
          for (const work of etc.procurement.children) {
            for (const [index, item] of work.detail.entries()) {
              item.ready_count = [0];
              item.ready_formula = [0];
              item.ready_acc_count = 0;
            }
          }
          for (const item of etc.procurement.items) {
            item.ready_count = [0];
            item.ready_formula = [0];
            item.ready_acc_count = 0;
          }
        } else if (kind === "contract") {
          if (originType === "change") {
            for (const work of etc.procurement.children) {
              for (const [index, item] of work.detail.entries()) {
                if (item.origin) {
                  work.detail.splice(index, 1);
                }
              }
              for (const item of work.detail) {
                item.origin = true;
                item.applyPercent = false;
              }
            }
            for (const [index, item] of etc.procurement.items.entries()) {
              if (item.origin) {
                etc.procurement.items.splice(index, 1);
              }
            }
            for (const item of etc.procurement.items) {
              item.applyPercent = false;
              item.origin = true;
            }
          } else {
            for (const work of etc.procurement.children) {
              for (const item of work.detail) {
                item.applyPercent = false;
              }
            }
            for (const item of etc.procurement.items) {
              item.applyPercent = false;
            }
          }
        }
      }
      if (etc.directed) {
        if (kind === "change" || kind === "complete") {
          const directedCopy = _.cloneDeep(etc.directed);
          for (const [workIndex, work] of (
            etc.directed.children || []
          ).entries()) {
            for (const item of work.detail) {
              item.origin = true;
              item.newInChange = false;
            }
            for (const [index, item] of directedCopy.children[
              workIndex
            ].detail.entries()) {
              item.origin = false;
              item.newInChange = false;
              work.detail.splice(index * 2 + 1, 0, item);
            }
          }
          for (const item of etc.directed.items) {
            item.origin = true;
            item.newInChange = false;
          }
          for (const [index, item] of directedCopy.items.entries()) {
            item.origin = false;
            item.newInChange = false;
            etc.directed.items.splice(index * 2 + 1, 0, item);
          }
        } else if (kind === "ready") {
          for (const work of etc.directed.children) {
            for (const [index, item] of work.detail.entries()) {
              item.ready_count = [0];
              item.ready_formula = [0];
              item.ready_acc_count = 0;
            }
          }
          for (const item of etc.directed.items) {
            item.ready_count = [0];
            item.ready_formula = [0];
            item.ready_acc_count = 0;
          }
        } else if (kind === "contract") {
          if (originType === "change") {
            for (const work of etc.directed.children) {
              for (const [index, item] of work.detail.entries()) {
                if (item.origin) {
                  work.detail.splice(index, 1);
                }
              }
              for (const item of work.detail.entries()) {
                item.origin = true;
                item.applyPercent = true;
              }
            }
            for (const [index, item] of etc.directed.items.entries()) {
              if (item.origin) {
                etc.directed.items.splice(index, 1);
              }
            }
            for (const item of etc.directed.items.entries()) {
              item.applyPercent = true;
              item.origin = true;
            }
          } else {
            for (const [index, work] of (
              etc.directed.children || []
            ).entries()) {
              for (const item of work.detail.entries()) {
                item.applyPercent = true;
                item.origin = true;
              }
            }
            for (const item of etc.directed.items) {
              item.applyPercent = true;
              item.origin = true;
            }
          }
        }
      }
      if (etc.government) {
        if (kind === "change" || kind === "complete") {
          const doCopy = _.cloneDeep(etc.government.do);
          for (const [workIndex, work] of (
            etc.government.do.children || []
          ).entries()) {
            for (const item of work.detail) {
              item.origin = true;
              item.newInChange = false;
            }
            for (const [index, item] of doCopy.children[
              workIndex
            ].detail.entries()) {
              item.origin = false;
              item.newInChange = false;
              work.detail.splice(index * 2 + 1, 0, item);
            }
          }
          for (const item of etc.government.do.items) {
            item.origin = true;
            item.newInChange = false;
          }
          for (const [index, item] of doCopy.items.entries()) {
            item.origin = false;
            item.newInChange = false;
            etc.government.do.items.splice(index * 2 + 1, 0, item);
          }
          const guanCopy = _.cloneDeep(etc.government.guan);
          for (const [workIndex, work] of (
            etc.government.guan.children || []
          ).entries()) {
            for (const item of work.detail) {
              item.origin = true;
              item.newInChange = false;
            }
            for (const [index, item] of guanCopy.children[
              workIndex
            ].detail.entries()) {
              item.origin = false;
              item.newInChange = false;
              work.detail.splice(index * 2 + 1, 0, item);
            }
          }
          for (const item of etc.government.guan.items) {
            item.origin = true;
            item.newInChange = false;
          }

          for (const [index, item] of guanCopy.items.entries()) {
            item.origin = false;
            item.newInChange = false;
            etc.government.guan.items.splice(index * 2 + 1, 0, item);
          }
        } else if (kind === "ready") {
          for (const work of etc.government.do.children) {
            for (const item of work.detail) {
              item.ready_count = [0];
              item.ready_formula = [0];
              item.ready_acc_count = 0;
            }
          }
          for (const work of etc.government.guan.children || []) {
            for (const item of work.detail) {
              item.ready_count = [0];
              item.ready_formula = [0];
              item.ready_acc_count = 0;
            }
          }
          for (const item of etc.government.do.items) {
            item.ready_count = [0];
            item.ready_formula = [0];
            item.ready_acc_count = 0;
          }
          for (const item of etc.government.guan.items) {
            item.ready_count = [0];
            item.ready_formula = [0];
            item.ready_acc_count = 0;
          }
        } else if (kind === "contract") {
          if (originType === "change") {
            for (const work of etc.government.guan.children || []) {
              for (const [index, item] of work.detail.entries()) {
                if (item.origin) {
                  work.detail.splice(index, 1);
                }
              }
              for (const item of work.detail) {
                item.applyPercent = false;
                item.origin = true;
              }
            }
            for (const work of etc.government.do.children) {
              for (const [index, item] of work.detail.entries()) {
                if (item.origin) {
                  work.detail.splice(index, 1);
                }
              }
              for (const item of work.detail) {
                item.applyPercent = false;
                item.origin = true;
              }
            }
            for (const [index, item] of etc.government.guan.items.entries()) {
              if (item.origin) {
                etc.government.guan.items.splice(index, 1);
              }
            }
            for (const item of etc.government.guan.items.entries()) {
              item.applyPercent = false;
              item.origin = true;
            }
            for (const [index, item] of etc.government.do.items.entries()) {
              if (item.origin) {
                etc.government.do.items.splice(index, 1);
              }
            }
            for (const item of etc.government.do.items.entries()) {
              item.applyPercent = false;
              item.origin = true;
            }
          } else {
            for (const work of etc.government.guan.children || []) {
              for (const [index, item] of (work.detail || []).entries()) {
                item.applyPercent = false;
                item.origin = true;
              }
            }
            for (const work of etc.government.do.children || []) {
              for (const [index, item] of (work.detail || []).entries()) {
                item.applyPercent = false;
                item.origin = true;
              }
            }
            for (const [index, item] of (
              etc.government.guan.items || []
            ).entries()) {
              item.applyPercent = false;
              item.origin = true;
            }
            for (const [index, item] of (
              etc.government.do.items || []
            ).entries()) {
              item.applyPercent = false;
              item.origin = true;
            }
          }
        }
      }
    }
    if (project.invoice_rate && (kind === "change" || kind === "complete")) {
      project.invoice_rate["etcRateChange"] = project.invoice_rate["etcRate"];
      project.invoice_rate["indirectRateChange"] =
        project.invoice_rate["indirectRate"];
      project.invoice_rate["healthManageRateChange"] =
        project.invoice_rate["healthManageRate"];
      project.invoice_rate["healthManageGovernmentChange"] =
        project.invoice_rate["healthManageGovernment"];
      project.invoice_rate["healthInsuranceRateChange"] =
        project.invoice_rate["healthInsuranceRate"];
      project.invoice_rate["pensionInsuranceRateChange"] =
        project.invoice_rate["pensionInsuranceRate"];
      project.invoice_rate["oldEnsuranceRateChange"] =
        project.invoice_rate["oldEnsuranceRate"];
      project.invoice_rate["industrialEnsuranceRateChange"] =
        project.invoice_rate["industrialEnsuranceRate"];
      project.invoice_rate["environmentRateChange"] =
        project.invoice_rate["environmentRate"];
      project.invoice_rate["employmentInsuranceRateChange"] =
        project.invoice_rate["employmentInsuranceRate"];
      project.invoice_rate["generalMaintenanceRateChange"] =
        project.invoice_rate["generalMaintenanceRate"];
      project.invoice_rate["profitRateChange"] =
        project.invoice_rate["profitRate"];
      project.invoice_rate["constructionMachineRentRateChange"] =
        project.invoice_rate["constructionMachineRentRate"];
      project.invoice_rate["retirementInsuranceRateChange"] =
        project.invoice_rate["retirementInsuranceRate"];
      project.invoice_rate["doCuttingChange"] =
        project.invoice_rate["doCutting"];
      project.invoice_rate["governmentCuttingChange"] =
        project.invoice_rate["governmentCutting"];
      project.invoice_rate["totalCuttingChange"] =
        project.invoice_rate["totalCutting"];
      project.invoice_rate["contractExpenses"] = [
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
      ];
    }
    project.project_info["materialPercent"] =
      project.project_info.materialPercent || 100;
    project.project_info["laborPercent"] =
      project.project_info.laborPercent || 100;
    project.project_info["expensePercent"] =
      project.project_info.expensePercent || 100;
    project.project_info["materialPercentChange"] =
      project.project_info.materialPercent || 100;
    project.project_info["laborPercentChange"] =
      project.project_info.laborPercent || 100;
    project.project_info["expensePercentChange"] =
      project.project_info.expensePercent || 100;
    project.project_info["materialPercentTemp"] =
      project.project_info["materialPercent"];
    project.project_info["laborPercentTemp"] =
      project.project_info["laborPercent"];
    project.project_info["expensePercentTemp"] =
      project.project_info["expensePercent"];

    if (kind === "contract" && originType === "change") {
      project.invoice_rate["contractExpensesOrigin"] =
        project.invoice_rate["contractExpenses"];
      project.invoice_rate["contractExpenses"] = [
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
      ];
      if (project.invoice_rate.expenseInvoices) {
        for (const invoice of project.invoice_rate.expenseInvoices) {
          if (invoice.change?.follow) {
            invoice.absoluteValue = invoice.change.value;
          }
          if (invoice.change) {
            invoice.formula = invoice.change.formula;
            invoice.rate = invoice.change.rate;
          }
        }
      }
      if (project.invoice_rate.laborInvoices) {
        for (const invoice of project.invoice_rate.laborInvoices) {
          if (invoice.change) {
            invoice.formula = invoice.change.formula;
            invoice.rate = invoice.change.rate;
          }
        }
      }
      if (project.invoice_rate.laborInvoices) {
        for (const invoice of project.invoice_rate.laborInvoices) {
          if (invoice.change) {
            invoice.formula = invoice.change.formula;
            invoice.rate = invoice.change.rate;
          }
        }
      }
      // beforeVatInvoices
      // afterVatInvoices
      // generalInvoice
      if (project.invoice_rate.beforeVatInvoices) {
        for (const invoice of project.invoice_rate.beforeVatInvoices) {
          if (invoice.change) {
            invoice.formula = invoice.change.formula;
            invoice.rate = invoice.change.rate;
          }
        }
      }
      if (project.invoice_rate.afterVatInvoices) {
        for (const invoice of project.invoice_rate.afterVatInvoices) {
          if (invoice.change) {
            invoice.formula = invoice.change.formula;
            invoice.rate = invoice.change.rate;
          }
        }
      }
      if (project.invoice_rate.generalInvoice) {
        if (project.invoice_rate.generalInvoice.change) {
          project.invoice_rate.generalInvoice.formula =
            project.invoice_rate.generalInvoice.change.formula;
          project.invoice_rate.generalInvoice.rate =
            project.invoice_rate.generalInvoice.change.rate;
        }
      }
    }
    if (kind === "complete") {
      if (project.invoice_rate.expenseInvoices) {
        for (const invoice of project.invoice_rate.expenseInvoices) {
          if (invoice.change && invoice.change?.follow)
            invoice.complete = {
              absoluteValue: invoice.absoluteValue || invoice.value,
              originValue: invoice.absoluteValue || invoice.value,
            };
        }
      }
    }

    const statements = project.statements;
    const priceCalculations = project.price_calculations;
    const laborPrices = project.labor_prices;
    const itemizeCosts = project.itemize_costs;
    const machinePrices = project.machine_prices;
    const materialPrices = project.material_prices;
    const projectId = id;
    const projectName = project.name;
    const invoiceRate = project.invoice_rate;
    const projectInfo = project.project_info;
    const permissions = project.permissions;
    const exchangeRate = project.exchange_rate;
    const wageFactor = project.wage_factor;
    const etc = project.etc;
    const readyLength = project.ready_length;
    const projectStartDate = project.start_date;
    const projectEndDate = project.end_date;
    const decimalPoint = project.decimal_point;
    await this.props.dupLogin.checkDupLogin(() =>
      api.saveProject({
        projectName,
        projectId,
        statements,
        priceCalculations,
        laborPrices,
        itemizeCosts,
        machinePrices,
        materialPrices,
        invoiceRate,
        projectInfo,
        permissions,
        exchangeRate,
        wageFactor,
        etc,
        readyLength,
        projectStartDate,
        projectEndDate,
        decimalPoint,
      })
    );
  }

  reculsiveCloneDetail = (work: any, kind: string, originType?: string) => {
    for (const cItem of work.children) {
      if (kind === "change" || kind === "complete") {
        const details = cItem.detail;
        const detailsCopy = _.cloneDeep(details);
        for (const detail of details) {
          detail.origin = true;
          detail.newInChange = false;
        }
        for (const [index, detail] of detailsCopy.entries()) {
          detail.origin = false;
          detail.newInChange = false;
          details.splice(index * 2 + 1, 0, detail);
        }
      } else if (kind === "ready") {
        const details = cItem.detail;
        for (const detail of details) {
          detail.ready_count = [0];
          detail.ready_formula = [0];
          detail.ready_acc_count = 0;
        }
      } else if (kind === "contract") {
        const details = cItem.detail;
        for (const detail of details) {
          detail.applyPercent = true;
        }
        if (originType === "change") {
          for (const [index, item] of details.entries()) {
            if (item.origin) {
              details.splice(index, 1);
            }
          }
          for (const item of details.entries()) {
            item.origin = true;
          }
        }
      }

      if (cItem.children) {
        this.reculsiveCloneDetail(cItem, kind, originType);
      }
    }
    if (work?.detail?.length > 0) {
      const details = work.detail;
      if (kind === "change" || kind === "complete") {
        const detailsCopy = _.cloneDeep(details);
        for (const detail of details) {
          detail.origin = true;
          detail.newInChange = false;
        }
        for (const [index, detail] of detailsCopy.entries()) {
          detail.origin = false;
          detail.newInChange = false;
          details.splice(index * 2 + 1, 0, detail);
        }
      } else if (kind === "ready") {
        for (const detail of details) {
          detail.ready_count = [0];
          detail.ready_formula = [0];
          detail.ready_acc_count = 0;
        }
      } else if (kind === "contract") {
        for (const detail of details) {
          detail.applyPercent = true;
        }
        if (originType === "change") {
          for (const [index, item] of details.entries()) {
            if (item.origin) {
              details.splice(index, 1);
            }
          }
          for (const item of details.entries()) {
            item.origin = true;
          }
        }
      }
    }
  };

  handleLectureClick = () => {
    const typeLecturePair: any = {
      contract: "7/27",
      change: "7/28",
      ready: "7/30",
      complete: "7/32",
    };
    const a = document.createElement("a");
    a.target = "_blank";
    a.href =
      process.env.REACT_APP_URL +
      "lectureroom/" +
      typeLecturePair[this.state.type];
    a.click();
  };

  render() {
    // if (this.state.isProjectDeleted) {
    //   // url의 쿼리스트링에서 type 가져오기
    //   const url = new URL(window.location.href);
    //   const type = url.searchParams.get("type");

    //   return <Redirect to={pages.costprojects + "/" + type} />;
    // }

    return (
      <main id="wrap">
        <article>
          <section>
            <div className="container page">
              <div
                onClick={() => {
                  window.history.back();
                }}
                className="btn text-gray-400 mb-3 left-icon back-btn"
              >
                <span className="material-symbols-outlined me-2">
                  keyboard_backspace
                </span>
                목록으로
              </div>
              <div className="col-12">
                <div className="section-header">
                  <div className="d-flex align-items-center">
                    프로젝트 선택
                    <Link
                      to={pages.lectureroom + "/0"}
                      className="btn text-gray-400"
                    >
                      <span className="material-symbols-outlined">help</span>
                    </Link>
                  </div>
                </div>
                <ul className="nav nav-tabs" id="project-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="normal-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#normal-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="normal-tab-pane"
                      aria-selected="true"
                      onClick={() =>
                        this.selectType(this.state.copy ? "copy" : "normal")
                      }
                    >
                      일반
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contract-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contract-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="contract-tab-pane"
                      aria-selected="false"
                      tabIndex={-1}
                      onClick={() => this.selectType("contract")}
                    >
                      계약
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="change-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#change-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="change-tab-pane"
                      aria-selected="false"
                      tabIndex={-1}
                      onClick={() => this.selectType("change")}
                    >
                      변경
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="ready-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#ready-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="ready-tab-pane"
                      aria-selected="false"
                      tabIndex={-1}
                      onClick={() => this.selectType("ready")}
                    >
                      기성
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="complete-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#complete-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="complete-tab-pane"
                      aria-selected="false"
                      tabIndex={-1}
                      onClick={() => this.selectType("complete")}
                    >
                      준공
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="project-tabContent">
                  {tab.map((item, index) => {
                    // console.log(item);
                    const itemName =
                      this.state.copy && index == 0 ? "copy" : item;
                    return (
                      <div
                        className={
                          index == 0
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id={item + "-tab-pane"}
                        role="tabpanel"
                        aria-labelledby={item + "-tab"}
                      >
                        <div className="table-responsive mt-6">
                          <table className="table">
                            <thead>
                              <tr className="table-light">
                                <th scope="col" className="text-center">
                                  제목
                                </th>
                                <th scope="col" className="text-center">
                                  생성일
                                </th>
                                <th scope="col" className="text-center">
                                  관리
                                </th>
                              </tr>
                            </thead>
                            <tbody className="table-group-divider">
                              {this.state.projects?.map(
                                (project: Project, i: number) => (
                                  <tr
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.clickRow(project.id);
                                    }}
                                  >
                                    <td
                                      className="text-center"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {project.name}
                                    </td>
                                    <td className="text-center">
                                      {project.created.format(
                                        "yyyy-MM-dd HH:mm"
                                      )}
                                    </td>
                                    <td className="text-center">
                                      <div className="project-btn-wrap">
                                        {/* <button
                                          type="button"
                                          className="btn btn-primary-soft"
                                          data-bs-toggle="modal"
                                          data-bs-target="#project-editModal"
                                          onClick={(event) => {
                                            this.clickEdit(event, project);
                                          }}
                                        >
                                          <span className="material-symbols-outlined">
                                            edit
                                          </span>
                                          수정
                                        </button> */}
                                        <button
                                          type="button"
                                          className="btn btn-danger-soft"
                                          data-bs-toggle="modal"
                                          data-bs-target="#dialogs"
                                          onClick={(event) => {
                                            this.clickRemove(event, project);
                                          }}
                                        >
                                          <span className="material-symbols-outlined">
                                            delete
                                          </span>
                                          삭제
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                        {!["normal", "copy"].includes(this.state.type) &&
                          !this.state?.isExpired && (
                            <button
                              type="button"
                              className="btn w-100 btn-lg btn-primary-soft"
                              data-bs-toggle="modal"
                              data-bs-target="#project-addModal"
                              onClick={() => {
                                this.onClickCreateConfirm();
                              }}
                            >
                              <span className="material-symbols-outlined">
                                add
                              </span>
                              새로운 프로젝트
                            </button>
                          )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </article>
        <DeleteConfirm
          open={this.state.removeNormalConfirmOpen}
          onClickConfirm={this.onClickRemoveConfirm}
          onClickCancel={this.onClickRemoveCancel}
        />
        {/* <SimpleConfirm
          text={`${this.getProjectTypeText(
            this.state.type
          )} 프로젝트를 만드시겠습니까?`}
          open={this.state.createConfirmOpen}
          onClickConfirm={this.onClickCreateConfirm}
          onClickCancel={this.onClickCreateCancel}
        /> */}
        <EditProjectModal
          open={this.state.editOpen}
          project={this.state.selectedProject}
          onClose={this.onEditClose}
        />
        <SelectProjectOriginModal
          open={this.state.selectOriginOpen}
          projects={this.projects}
          type={this.state.type}
          onClose={this.onOriginClose}
        />
      </main>
    );
  }
}
CostProjectsDetailPage.contextType = AlertContext;
