import "../searchResult.scss";
import React, { useEffect, useRef, useState, useCallback } from "react";
import AiSearchInput from "../../../components/AiSearchInput/AiSearchInput";
import SearchResultAside from "../SearchResultAside";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { aiApi } from "../../../config/aiApi";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import MarkdownTyper from "../../../components/MarkdownTyper";
import StatementCitations from "./statementCitations";
import TableTyper from "../../../components/TableTyper/TableTyper";
import ProjectTyper from "../../../components/ProjectTyper/ProjectTyper";
interface UserStatement {
  conversation_id?: string;
  isNew?: boolean;
  price_id?: string[] | string;
  itemized_id?: string[] | string;
  price_titles?: string[] | string;
  itemized_titles?: string[] | string;
  id: number;
  user_id: number;
  content: string;
  role: string;
  created_at?: string;
}
export default function StatementResult() {
  const location = useLocation();
  const [projectPreview, setProjectPreview] = useState<any[]>([]);
  const [projects, setProjects] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<any[]>([]);

  const [userStatement, setUserStatement] = useState<UserStatement[]>([]);
  const [assistantStatement, setAssistantStatement] = useState<UserStatement[]>(
    []
  );
  const searchResultRef = useRef<HTMLDivElement | null>(null);
  const [projectData, setProjectData] = useState<any[]>([]);
  const [conversationId, setConversationId] = useState<string>("");
  const [priceData, setPriceData] = useState<any[]>([]);
  const [isPreview, setIsPreview] = useState<{ [key: number]: boolean }>({});
  const joinTexts = (name: string, standard: string) => {
    return standard ? `${name} - ${standard}` : name;
  };
  const writeData = useCallback(
    async (content: string) => {
      if (!conversationId) return;
      try {
        const completion = await aiApi.getStateCompletion(content);
        console.log("completion", completion);
        const itemized = completion.itemized_costs
          ? completion.itemized_costs.map((item: any) => item.id)
          : [];
        const price = completion.calculate_objects
          ? completion.calculate_objects.map((item: any) => item.id)
          : [];

        setPriceData(completion.calculate_objects);
        const postRes = await aiApi.postStatement({
          content: "test",
          role: "assistant",
          conversation_id: conversationId,
          itemized_cost_id: itemized.toString(),
          itemized_cost_titles: completion.itemized_costs
            ? completion.itemized_costs.map((item: any) =>
                joinTexts(item.name, item.standard.trim())
              )
            : [],
          price_calculation_id: price.toString(),
          price_calculation_titles: completion.calculate_objects
            ? completion.calculate_objects.map((item: any) =>
                joinTexts(item.name, item.standard.trim())
              )
            : [],
          itemized_has_project: completion.itemized_costs
            ? completion.itemized_costs.map((item: any) => item.has_projects)
            : [],
          price_has_project: completion.calculate_objects
            ? completion.calculate_objects.map((item: any) => item.has_projects)
            : [],
        });
        setAssistantStatement((prev) => [
          ...prev,
          {
            id: postRes.statement.id,
            user_id: postRes.statement.user_id,
            content: postRes.statement.content,
            role: "assistant",
            isNew: true,
            price_calculation_id: price.toString(),
            itemized_cost_id: itemized.toString(),
            price_calculation_titles: completion.calculate_objects
              ? completion.calculate_objects.map((item: any) =>
                  joinTexts(item.name, item.standard.trim())
                )
              : [],
            itemized_cost_titles: completion.itemized_costs
              ? completion.itemized_costs.map((item: any) =>
                  joinTexts(item.name, item.standard.trim())
                )
              : [],
          },
        ]);
        setSearchResult((prev) => [
          ...prev,
          {
            content: "asdf",
            role: "assistant",
            isNew: true,
            price_calculation_id: price.toString(),
            itemized_cost_id: itemized.toString(),
            price_calculation_titles: completion.calculate_objects
              ? completion.calculate_objects.map((item: any) =>
                  joinTexts(item.name, item.standard.trim())
                )
              : [],
            itemized_cost_titles: completion.itemized_costs
              ? completion.itemized_costs.map((item: any) =>
                  joinTexts(item.name, item.standard.trim())
                )
              : [],
            itemized_has_project: completion.itemized_costs
              ? completion.itemized_costs.map((item: any) => item.has_projects)
              : [],
            price_has_project: completion.calculate_objects
              ? completion.calculate_objects.map(
                  (item: any) => item.has_projects
                )
              : [],
          },
        ]);
      } catch (error) {
        setAssistantStatement((prev) => {
          return [
            ...prev,
            {
              id: 0,
              user_id: 0,
              conversation_id: conversationId,
              content: "죄송합니다. 응답을 생성하는 중 오류가 발생했습니다.",
              role: "assistant",
              isNew: false,
              price_calculation_id: [],
              itemized_cost_id: [],
              price_calculation_titles: [],
              itemized_cost_titles: [],
            },
          ];
        });
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    },
    [conversationId]
  );

  useEffect(() => {
    const updateHeight = () => {
      const stickyTopElement = document.querySelector(".sticky-top");
      if (searchResultRef.current && stickyTopElement) {
        const stickyHeight = stickyTopElement.clientHeight;
        searchResultRef.current.style.minHeight = `calc(100vh - ${stickyHeight}px)`;
      }
    };
    const init = async () => {
      const parsed = queryString.parse(location.search);
      const value = parsed.value as string;
      const conversationId = parsed.id as string;
      setConversationId(conversationId || "");
      try {
        setLoading(true);
        const res = await aiApi.getStatement(conversationId);
        if (conversationId) {
          console.log("res", res);
          const searchRes = res.statement
            .filter((item: any) => item.role === "assistant")
            .map((item: any) => {
              return {
                ...item,
                itemized_cost_id: Array.isArray(item.itemized_id)
                  ? item.itemized_id
                  : item.itemized_id?.split(","),
                price_calculation_id: Array.isArray(item.price_id)
                  ? item.price_id
                  : item.price_id?.split(","),
                itemized_cost_titles: Array.isArray(item.itemized_titles)
                  ? item.itemized_titles
                  : item.itemized_titles?.split(","),
                price_calculation_titles: Array.isArray(item.price_titles)
                  ? item.price_titles
                  : item.price_titles?.split(","),
                isNew: false,
                itemized_has_project: Array.isArray(item.itemized_has_project)
                  ? item.itemized_has_project
                  : item.itemized_has_project?.split(","),
                price_has_project: Array.isArray(item.price_has_project)
                  ? item.price_has_project
                  : item.price_has_project?.split(","),
              };
            });
          console.log("searchRes", searchRes);
          setAssistantStatement(searchRes);
          setSearchResult(searchRes);
          const user = res.statement.filter(
            (item: UserStatement) => item.role === "user"
          );
          setUserStatement(user);
          if (res.statement[res.statement.length - 1].role === "user") {
            writeData(res.statement[res.statement.length - 1].content);
          }
        } else {
          setUserStatement([
            {
              id: 1,
              user_id: 1,
              content: value as string,
              role: "user",
              created_at: "2024-01-01",
            },
          ]);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };

    init();
    updateHeight();
  }, [location.search, writeData]);
  const onSearchSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent | null,
    content: string
  ) => {
    if (loading) return;
    try {
      setLoading(true);
      const res = await aiApi.postStatement({
        content: content,
        role: "user",
        conversation_id: conversationId,
      });
      setUserStatement((prev) => [
        ...prev,
        {
          id: res.statement.id,
          user_id: res.statement.user_id,
          content: res.statement.content,
          role: "user",
        },
      ]);
      writeData(content);
    } catch (error) {
      console.log("error", error);
    }
  };
  const complete = (el: HTMLDivElement) => {
    window.scrollTo({
      top: el?.offsetTop - 120,
      behavior: "smooth",
    });
    setLoading(false);
  };
  const showPreview = (index: number) => {
    setIsPreview((prev) => {
      const newState = { ...prev };
      Object.keys(newState).forEach((key) => {
        newState[parseInt(key)] = false;
      });
      newState[index] = true;
      return newState;
    });
  };
  useEffect(() => {
    const array: any[] = [];
    userStatement.forEach((item) => {
      array.push({});
    });
    setProjectPreview(array);
    setProjects(array);
  }, [userStatement]);

  useEffect(() => {
    console.log("projectPreview", projectPreview);
  }, [projectPreview]);
  return (
    <div className="search-result" ref={searchResultRef}>
      <SearchResultAside />
      <div className="search-result-content">
        {userStatement.map((message, index) =>
          message.role === "user" ? (
            <div key={index} className="search-result-content-body">
              <div className="search-result-content-body-item-list">
                <div className="search-result-content-body-title">
                  <span className="search-item-title">{message.content}</span>
                </div>
                <div className="search-result-content-body-item">
                  <span className="search-result-content-body-item-title">
                    <SearchOutlined />
                    <span>답변</span>
                  </span>
                  <div className="search-item">
                    {assistantStatement && assistantStatement[index] ? (
                      <div className="search-item-content">
                        <MarkdownTyper
                          isNew={assistantStatement[index].isNew || false}
                          markdown={assistantStatement[index].content || ""}
                          onComplete={(el: HTMLDivElement) => {
                            complete(el);
                          }}
                        />
                        {projects[index] && projects[index].item_name && (
                          <div className="search-item-content-statement">
                            <ProjectTyper
                              projectList={projects[index]}
                              onProjectSelected={(project: any) => {
                                const newArray = [...projectPreview];
                                newArray[index] = project;
                                setProjectPreview(newArray);
                                showPreview(index);
                              }}
                            />
                          </div>
                        )}
                        {projectPreview[index] &&
                          projectPreview[index].statements && (
                            <div className="search-item-content-statement">
                              <TableTyper
                                data={projectPreview[index]}
                                isPreview={isPreview[index] || false}
                              />
                            </div>
                          )}
                      </div>
                    ) : (
                      <span className="ant-design-icon">
                        <LoadingOutlined />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="search-result-content-body-citations">
                <StatementCitations
                  citations={searchResult[index]}
                  onPreviewData={(data: any) => {
                    const newArray = [...projects];
                    newArray[index] = data;
                    setProjects(newArray);
                    setIsPreview({ [index]: false });
                  }}
                />
              </div>
            </div>
          ) : null
        )}
        <div className="search-result-content-footer">
          <AiSearchInput
            loading={loading}
            onSearchSubmit={onSearchSubmit}
            placeholder="추가 질문을 입력해주세요. 예) 추가적으로 알려줄 수 있나요?"
            tooltipText="현재 무료로 PRO 기능을 이용중입니다<br>최적화된 답변을 받을 수 있습니다."
          />
        </div>
      </div>
    </div>
  );
}
