import React, { useEffect, useState } from "react";
import AIStatement from "../../../components/AIStatement/AIStatement";
import { aiApi } from "../../../config/aiApi";
import { AlertContext } from "../../../contexts/AlertContext";
import { useContext } from "react";
import { LoadingContext } from "../../../contexts/LoadingContext";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";

interface StatementCitationsProps {
  citations: any;
  onPreviewData?: (data: any) => void;
}

interface GroupedItem {
  title: string;
  items: {
    id: string;
    specification?: string;
  }[];
  hasProject: string;
}

const StatementCitations: React.FC<StatementCitationsProps> = ({
  citations,
  onPreviewData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [checkedItemizedCosts, setCheckedItemizedCosts] = useState<string[]>(
    []
  );
  const [checkedPriceCalculations, setCheckedPriceCalculations] = useState<
    string[]
  >([]);
  const alertContext = useContext(AlertContext);
  const loading = useContext(LoadingContext);
  const [selectedType, setSelectedType] = useState<"price" | "statement">(
    "statement"
  );

  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});

  const [visibleItems, setVisibleItems] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handlePreview = (
    id: string,
    type: "price" | "statement" = "statement"
  ) => {
    setSelectedId(id);
    setSelectedType(type);
    setIsModalOpen(true);
  };
  const handleProjectPreview = async (
    id?: string,
    name?: string,
    type?: "price_calculation" | "itemized"
  ) => {
    try {
      const response = await aiApi.getProjectPreview(id, name, type);
      if (onPreviewData) {
        onPreviewData(response);
      }
    } catch (error) {
      if (onPreviewData) {
        onPreviewData({
          item_name: name,
          message: `${name} 프로젝트가 없습니다.`,
        });
      }
    }
  };

  const handleItemizedCheckboxChange = (id: string) => {
    setCheckedItemizedCosts((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  };

  const handlePriceCheckboxChange = (id: string) => {
    setCheckedPriceCalculations((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  };

  const handleExcelDownload = async () => {
    if (
      checkedItemizedCosts.length === 0 &&
      checkedPriceCalculations.length === 0
    ) {
      alertContext.showAlert("다운로드할 항목을 선택해주세요.");
      return;
    }

    try {
      loading.showLoading("엑셀 파일을 생성하는 중입니다.");

      const requestData = {
        itemized_cost_ids: checkedItemizedCosts.map((id) => parseInt(id)),
        price_calculation_ids: checkedPriceCalculations.map((id) =>
          parseInt(id)
        ),
      };
      const response = await aiApi.getStateExcel(requestData);

      if (response.excel_file_url?.length > 0) {
        let url = response.excel_file_url.replace(/\+/g, "%2B");

        const link = document.createElement("a");
        link.href = url;
        link.download = `일위대가_${new Date().getTime()}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        alertContext.showAlert("파일 다운로드가 완료되었습니다.");
      } else {
        alertContext.showAlert("다운로드할 파일이 없습니다.");
      }
    } catch (error) {
      console.error("Excel download error:", error);
      alertContext.showAlert("파일 다운로드 중 오류가 발생했습니다.");
    } finally {
      loading.closeLoading();
    }
  };

  const getCitationsArray = (array: any) => {
    if (!array) return [];
    if (Array.isArray(array)) return array;
    if (typeof array === "string") {
      try {
        const parsed = JSON.parse(array);
        return Array.isArray(parsed) ? parsed : [parsed];
      } catch {
        return array.split(",").map((item: any) => item.trim());
      }
    }
    return [array]; // 단일 값인 경우 배열로 변환
  };

  const itemizedArray = getCitationsArray(citations?.itemized_cost_id);
  const priceArray = getCitationsArray(citations?.price_calculation_id);
  const itemizedHasProject = getCitationsArray(citations?.itemized_has_project)
    .map((value: any) => {
      if (typeof value === 'boolean') return value;
      if (typeof value === 'string') return value.toLowerCase() === 'true';
      return false;
    });
  const priceHasProject = getCitationsArray(citations?.price_has_project)
    .map((value: any) => {
      if (typeof value === 'boolean') return value;
      if (typeof value === 'string') return value.toLowerCase() === 'true';
      return false;
    });

  // 제목과 규격을 분리하는 함수
  const separateTitleAndSpec = (fullTitle: string) => {
    const parts = fullTitle.split(" - ");
    return {
      title: parts[0],
      specification: parts.slice(1).join(" - "), // 규격에 '-'가 포함될 수 있으므로
    };
  };

  // 동일한 이름의 항목들을 그룹화하는 함수
  const groupItems = (
    items: string[],
    titles: string[] = [],
    hasProject: any[] = []
  ) => {
    const groups: { [key: string]: GroupedItem } = {};
    items.forEach((id, index) => {
      if (!id || !titles[index]) return;

      const { title, specification } = separateTitleAndSpec(titles[index]);

      if (!groups[title]) {
        groups[title] = {
          title,
          items: [],
          hasProject: String(hasProject[index]), // boolean 값을 문자열로 변환
        };
      }

      groups[title].items.push({
        id,
        specification,
      });
    });
    return Object.values(groups);
  };

  const toggleSection = (title: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  useEffect(() => {
    if (!citations) return;
    
    const itemizedHasProject = getCitationsArray(citations?.itemized_has_project)
      .map((value: any) => {
        if (typeof value === 'boolean') return value;
        if (typeof value === 'string') return value.toLowerCase() === 'true';
        return false;
      });
      
    const priceHasProject = getCitationsArray(citations?.price_has_project)
      .map((value: any) => {
        if (typeof value === 'boolean') return value;
        if (typeof value === 'string') return value.toLowerCase() === 'true';
        return false;
      });

    const itemizedIds = getCitationsArray(citations?.itemized_cost_id)
      .map((id: any) => `itemized-${id}`);
      
    const priceIds = getCitationsArray(citations?.price_calculation_id)
      .map((id: any) => `price-${id}`);

    // 이전 상태 초기화
    setVisibleItems({});
    let currentIndex = 0;
    let currentPriceIndex = 0;

    const intervalId = setInterval(() => {
      if (currentIndex < itemizedIds.length) {
        setVisibleItems((prev) => ({
          ...prev,
          [itemizedIds[currentIndex]]: true,
        }));
        currentIndex++;
      } else if (currentPriceIndex < priceIds.length) {
        setVisibleItems((prev) => ({
          ...prev,
          [priceIds[currentPriceIndex]]: true,
        }));
        currentPriceIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [citations]);

  return (
    <>
      <div className="search-result-content-body-citations-item-list">
        {itemizedArray.length > 0 && (
          <div className="citation-section">
            {itemizedArray[0] !== "" && (
              <div className="citation-item-title">
                <span>일위대가 미리보기</span>
              </div>
            )}
            {groupItems(
              itemizedArray,
              citations?.itemized_cost_titles || [],
              itemizedHasProject
            ).map((group, groupIndex) => (
              <div key={groupIndex}>
                {group.items.length === 1 ? (
                  <div
                    className={`citation-single-item fade-in-item ${
                      visibleItems[`itemized-${group.items[0].id}`]
                        ? "visible"
                        : ""
                    }`}
                  >
                    <label className="checkbox-wrapper">
                      <input
                        type="checkbox"
                        checked={checkedItemizedCosts.includes(
                          group.items[0].id
                        )}
                        onChange={() =>
                          handleItemizedCheckboxChange(group.items[0].id)
                        }
                      />
                      <span>{group.title}</span>
                    </label>
                    <div className="citation-preview-button-wrapper">
                      <span
                        className="preview-icon-wrapper"
                        onClick={() => handlePreview(group.items[0].id)}
                      >
                        <SearchOutlined />
                        <span className="tooltip">상세 정보 보기</span>
                      </span>
                      {group.hasProject === "true" && (
                        <span
                          className="preview-icon-wrapper"
                          onClick={() =>
                            handleProjectPreview(
                              group.items[0].id,
                              group.title,
                              "itemized"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <EyeOutlined />
                          <span className="tooltip">프로젝트 미리보기</span>
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`citation-accordion fade-in-item ${
                      visibleItems[`itemized-${group.items[0].id}`]
                        ? "visible"
                        : ""
                    }`}
                  >
                    <div
                      className="citation-accordion-header"
                      onClick={() => toggleSection(group.title)}
                    >
                      <span
                        className={`arrow ${
                          expandedSections[group.title] ? "expanded" : ""
                        }`}
                      >
                        ▼
                      </span>
                      <span style={{ display: "flex", flex: 1 }}>
                        {group.title}
                      </span>
                      {group.hasProject === "true" && (
                        <span
                          className="preview-icon-wrapper"
                          onClick={() =>
                            handleProjectPreview(
                              group.items[0].id,
                              group.title,
                              "itemized"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <EyeOutlined />
                          <span className="tooltip">프로젝트 미리보기</span>
                        </span>
                      )}
                    </div>
                    <div
                      className={`citation-accordion-content ${
                        expandedSections[group.title] ? "expanded" : ""
                      }`}
                    >
                      {group.items.map((item, itemIndex) => (
                        <div key={itemIndex} className="citation-spec-item">
                          <label className="checkbox-wrapper">
                            <input
                              type="checkbox"
                              checked={checkedItemizedCosts.includes(item.id)}
                              onChange={() =>
                                handleItemizedCheckboxChange(item.id)
                              }
                            />
                            <span>
                              {group.title} - {item.specification}
                            </span>
                          </label>
                          <div className="citation-preview-button-wrapper">
                            <span
                              className="preview-icon-wrapper"
                              onClick={() => handlePreview(item.id)}
                            >
                              <SearchOutlined />
                              <span className="tooltip">상세 정보 보기</span>
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {priceArray.length > 0 && (
          <div className="citation-section">
            {priceArray[0] !== "" && (
              <div className="citation-item-title">
                <span>단가산출 미리보기</span>
              </div>
            )}
            {groupItems(
              priceArray,
              citations?.price_calculation_titles || [],
              priceHasProject
            ).map((group, groupIndex) => (
              <div key={groupIndex}>
                {group.items.length === 1 ? (
                  <div
                    className={`citation-single-item fade-in-item  ${
                      visibleItems[`price-${group.items[0].id}`]
                        ? "visible"
                        : ""
                    }`}
                  >
                    <label className="checkbox-wrapper">
                      <input
                        type="checkbox"
                        checked={checkedPriceCalculations.includes(
                          group.items[0].id
                        )}
                        onChange={() =>
                          handlePriceCheckboxChange(group.items[0].id)
                        }
                      />
                      <span>{group.title}</span>
                    </label>
                    <div className="citation-preview-button-wrapper">
                      <span
                        className="preview-icon-wrapper"
                        onClick={() =>
                          handlePreview(group.items[0].id, "price")
                        }
                      >
                        <SearchOutlined />
                        <span className="tooltip">상세 정보 보기</span>
                      </span>
                      {group.hasProject === "true" && (
                        <span
                          className="preview-icon-wrapper"
                          onClick={() =>
                            handleProjectPreview(
                              group.items[0].id,
                              group.title,
                              "price_calculation"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <EyeOutlined />
                          <span className="tooltip">프로젝트 미리보기</span>
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`citation-accordion fade-in-item ${
                      visibleItems[`price-${group.items[0].id}`]
                        ? "visible"
                        : ""
                    }`}
                  >
                    <div
                      className="citation-accordion-header"
                      onClick={() => toggleSection(group.title)}
                    >
                      <span
                        className={`arrow ${
                          expandedSections[group.title] ? "expanded" : ""
                        }`}
                      >
                        ▼
                      </span>
                      <span style={{ display: "flex", flex: 1 }}>
                        {group.title}
                      </span>
                      {group.hasProject === "true" && (
                        <span
                          className="preview-icon-wrapper"
                          onClick={() =>
                            handleProjectPreview(
                              group.items[0].id,
                              group.title,
                              "price_calculation"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <EyeOutlined />
                          <span className="tooltip">프로젝트 미리보기</span>
                        </span>
                      )}
                    </div>
                    <div
                      className={`citation-accordion-content ${
                        expandedSections[group.title] ? "expanded" : ""
                      }`}
                    >
                      {group.items.map((item, itemIndex) => (
                        <div key={itemIndex} className="citation-spec-item">
                          <label className="checkbox-wrapper">
                            <input
                              type="checkbox"
                              checked={checkedPriceCalculations.includes(
                                item.id
                              )}
                              onChange={() =>
                                handlePriceCheckboxChange(item.id)
                              }
                            />
                            <span>
                              {group.title} - {item.specification}
                            </span>
                          </label>
                          <div className="citation-preview-button-wrapper">
                            <span
                              className="preview-icon-wrapper"
                              onClick={() => handlePreview(item.id, "price")}
                            >
                              <SearchOutlined />
                              <span className="tooltip">상세 정보 보기</span>
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {(itemizedArray.length > 0 || priceArray.length > 0) && (
          <div className="download-button-wrapper">
            <button
              onClick={handleExcelDownload}
              disabled={
                checkedItemizedCosts.length === 0 &&
                checkedPriceCalculations.length === 0
              }
              className="excel-download-btn"
            >
              엑셀 다운로드
            </button>
          </div>
        )}
      </div>
      {isModalOpen && (
        <AIStatement
          statement={selectedId}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          type={selectedType}
        />
      )}
    </>
  );
};

export default StatementCitations;
