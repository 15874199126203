import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import "./searchResult.scss";
import React, { useEffect, useRef, useState, useCallback } from "react";
import AiSearchInput from "../../components/AiSearchInput/AiSearchInput";
import { aiApi } from "../../config/aiApi";
import MarkdownTyper from "../../components/MarkdownTyper";
import { AiChatCompletion, Message } from "../../models/aiSearchModel";
import SearchResultAside from "./SearchResultAside";
import SearchResultCitations from "./SearchResultCitations";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

interface Conversation {
  id: number;
  title: string;
  created_at: string;
}
interface ExtendedMessage extends Message {
  new: boolean;
}

export default function SearchResult() {
  const [searchValue, setSearchValue] = useState<string>();
  const [searchResult, setSearchResult] = useState<any>([]);
  const [searchUserMessages, setSearchUserMessages] = useState<Message[]>([]);
  const [searchAssistantMessages, setSearchAssistantMessages] = useState<
    ExtendedMessage[]
  >([]);

  const [historyConversation, setHistoryConversation] = useState<
    Conversation[]
  >([]);
  const [loading, setLoading] = useState(true);
  const searchResultRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const params = useRef<any>({});

  const fetchData = useCallback(async (result: string) => {
    if (!result) {
      return;
    }
    setLoading(true);
    console.log("fetch!!");
    const userMessage: Message = { role: "user", content: result };
    const res = await aiApi.chatCompletions([userMessage]);

    const createFormattedData = async (citations: any[], chatId: number) => {
      const previewsData = await Promise.all(
        citations.map((data: any) => aiApi.getLinkPreview(data))
      );
      return previewsData.map((item, index) => {
        const tags = item.tags || {};
        return {
          chat_id: chatId,
          title: tags.og_title || tags.title || null,
          description: tags.og_description || tags.description || null,
          url: tags.og_url || tags.url || citations[index],
          img: tags.og_image || tags.image || null,
        };
      });
    };

    if (res && res.choices) {
      const assistantMessage = {
        role: "assistant",
        content: res.choices[0].message.content,
        new: true,
      };

      if (params.current.seq) {
        const postMessage = await aiApi.postMessage({
          conversation_id: params.current.seq,
          ...assistantMessage,
        });
        const formattedData = await createFormattedData(
          res.citations,
          postMessage.message_id
        );
        const postCompletion = await aiApi.postCompletion(formattedData);
        console.log("postCompletion", postCompletion);
        setSearchAssistantMessages((prevMessages) => [
          ...prevMessages,
          assistantMessage,
        ]);
        setSearchResult((prevResult: any) => {
          const updatedResult = [...prevResult];
          updatedResult.push(formattedData);
          return updatedResult;
        });
      } else {
        const formattedData = await createFormattedData(res.citations, -1);
        setSearchAssistantMessages((prevMessages) => [
          ...prevMessages,
          assistantMessage,
        ]);
        setSearchResult((prevResult: any) => {
          const updatedResult = [...prevResult];
          updatedResult.push(formattedData);
          return updatedResult;
        });
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const init = async () => {
      const parsed = queryString.parse(location.search);
      params.current = parsed;
      const query = parsed.value;
      const seq = parsed.seq;
      if (query) {
        setSearchValue(query as string);
        return;
      }
      if (seq) {
        const res = await aiApi.getConversation();
        const messages = await aiApi.getMessage(seq as string);
        const completionMessages = await Promise.all(
          messages.messages.map(async (item: any) => {
            if (item.role === "assistant") {
              const completion = await aiApi.getCompletion(item.message_id);
              return completion;
            }
            return null;
          })
        );
        const filteredCompletionMessages = completionMessages.filter(
          (message) => message !== null
        );
        const nestedCompletions = filteredCompletionMessages.map(
          (message) => message.completions || []
        );
        setSearchResult(nestedCompletions);
        setHistoryConversation(
          res.conversations.map((item: any) => ({
            conversation_id: item.conversation_id,
            title: item.title,
            created_at: item.created_at,
          }))
        );
        setSearchUserMessages(
          messages.messages.filter((item: any) => item.role === "user")
        );
        setSearchAssistantMessages(
          messages.messages
            .filter((item: any) => item.role === "assistant")
            .map((item: any) => ({
              ...item,
              new: false,
            }))
        );

        if (messages.messages[messages.messages.length - 1].role === "user") {
          fetchData(messages.messages[messages.messages.length - 1].content);
        }
        return;
      }
    };
    const updateHeight = () => {
      const stickyTopElement = document.querySelector(".sticky-top");
      if (searchResultRef.current && stickyTopElement) {
        const stickyHeight = stickyTopElement.clientHeight;
        searchResultRef.current.style.minHeight = `calc(100vh - ${stickyHeight}px)`;
      }
    };
    updateHeight();
    init();
  }, [location.search]);

  useEffect(() => {
    if (searchValue) {
      console.log("searchvalue", searchValue);
      const userMessage: Message = { role: "user", content: searchValue };
      setSearchUserMessages((prevMessages) => [...prevMessages, userMessage]);
      fetchData(searchValue);
    }
  }, [searchValue]);

  async function onSearchSubmit(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent | null,
    result: string
  ) {
    if (params.current.seq) {
      try {
        await aiApi.postMessage({
          conversation_id: params.current.seq,
          role: "user",
          content: result,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    setSearchValue(result);
  }
  const complete = (el: HTMLDivElement) => {
    window.scrollTo({
      top: el?.offsetTop - 120,
      behavior: "smooth",
    });
    setLoading(false);
  };
  return (
    <div className="search-result bg-light-md" ref={searchResultRef}>
      <SearchResultAside />
      <div className="search-result-content">
        {searchUserMessages.map((message, index) =>
          message.role === "user" ? (
            <div key={index} className="search-result-content-body">
              <div className="search-result-content-body-item-list">
                <div className="search-result-content-body-title">
                  <span className="search-item-title">{message.content}</span>
                </div>
                <div className="search-result-content-body-item">
                  <span className="search-result-content-body-item-title">
                    <SearchOutlined />
                    <span>답변</span>
                  </span>
                  <span className="search-item">
                    {searchAssistantMessages &&
                    searchAssistantMessages[index] ? (
                      <MarkdownTyper
                        isNew={searchAssistantMessages[index].new}
                        markdown={searchAssistantMessages[index].content}
                        onComplete={(el: HTMLDivElement) => {
                          complete(el);
                        }}
                      />
                    ) : (
                      <span className="ant-design-icon">
                        <LoadingOutlined />
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <div className="search-result-content-body-citations">
                <SearchResultCitations citations={searchResult[index]} />
              </div>
            </div>
          ) : null
        )}
        <div className="search-result-content-footer">
          <AiSearchInput
            loading={loading}
            onSearchSubmit={onSearchSubmit}
            placeholder="추가 질문을 입력해주세요. 예) 추가적으로 알려줄 수 있나요?"
            tooltipText="현재 무료로 PRO 기능을 이용중입니다<br>최적화된 답변을 받을 수 있습니다."
          />
        </div>
      </div>
    </div>
  );
}
