import { SearchOutlined } from "@ant-design/icons";
import "./search.scss";
import React, { useEffect, useRef, useState, useContext } from "react";
import AiSearchInput from "../../components/AiSearchInput/AiSearchInput";
import queryString from "query-string";
import { aiApi } from "../../config/aiApi";
import { pages } from "../../constants/PagePaths";
import { useHistory } from "react-router-dom";
import { Cookies } from "../../constants/Cookies";
import { util } from "../../config/util";
import SearchResultAside from "../searchResult/SearchResultAside";
import AlertContext from "../../contexts/AlertContext";

export default function Search() {
  const alertContext = useContext(AlertContext);
  const searchContainerRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const [historyConversation, setHistoryConversation] = useState([]);
  const [searchCategory, setSearchCategory] = useState([
    {
      title: "기본 웹 검색",
      icon: <SearchOutlined />,
      active: true,
    },
    {
      title: "일위대가 자동생성",
      icon: <SearchOutlined />,
      active: false,
    },
    {
      title: "건설 품셈 도우미",
      icon: <SearchOutlined />,
      active: false,
    },
  ]);
  useEffect(() => {
    const updateHeight = () => {
      const stickyTopElement = document.querySelector(".sticky-top");
      if (stickyTopElement && searchContainerRef.current) {
        const stickyHeight = stickyTopElement.clientHeight;
        searchContainerRef.current.style.minHeight = `calc(100vh - ${stickyHeight}px)`;
      }
    };
    const getHistoryConversation = async () => {
      if (util.getCookie(Cookies.authKey)) {
        const res = await aiApi.getConversation();
        console.log("res", res);
        setHistoryConversation(res.conversations);
      }
    };
    getHistoryConversation();
    // 초기 높이 설정
    updateHeight();

    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  async function onSearchSubmit(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent,
    result: string
  ) {
    const key = util.getCookie(Cookies.authKey);
    if (searchCategory[0].active) {
      try {
        if (!key) {
          const param = queryString.stringify({
            value: result,
          });
          history.push(`${pages.searchResult}?${param}`);
        } else {
          const res = await aiApi.postConversastion(result, "search");
          await aiApi.postMessage({
            conversation_id: res.conversation_id,
            role: "user",
            content: result,
          });
          if (res.code === "0") {
            alertContext.showAlert("오류가 발생했습니다.");
            return;
          }
          const param = queryString.stringify({
            seq: res.conversation_id,
          });
          history.push(`${pages.searchResult}?${param}`);
        }
      } catch (error) {
        console.error(error);
        alertContext.showAlert(
          "오류가 발생했습니다. 고객센터를 통해 문의해 주세요."
        );
      }
    } else if (searchCategory[1].active) {
      if (!key) {
        const param = queryString.stringify({
          value: result,
        });
        history.push(`${pages.statementResult}?${param}`);
      } else {
        // POST API로 데이터 전송 후 페이지 이동
        try {
          const res = await aiApi.postConversastion(result, "statement");
          await aiApi.postStatement({
            content: result,
            role: "user",
            conversation_id: res.conversation_id,
          });
          const param = queryString.stringify({
            id: res.conversation_id,
          });
          history.push(`${pages.statementResult}?${param}`);
        } catch (error) {
          console.error(error);
          alertContext.showAlert(
            "오류가 발생했습니다. 고객센터를 통해 문의해 주세요."
          );
        }
      }
    } else if (searchCategory[2].active) {
      console.log("건설 품셈 도우미");
    }
  }
  function handleSearchCategoryClick(index: number) {
    const newSearchCategory = searchCategory.map((item, idx) => ({
      ...item,
      active: idx === index,
    }));
    setSearchCategory(newSearchCategory);
  }
  return (
    <section className="search-container">
      <div className="overlay"></div>
      <SearchResultAside />
      <div className="search-content" ref={searchContainerRef}>
        <h1>
          건설업의 미래가 시작되는 곳 <b>뚜껑설계 AI</b>
        </h1>
        <div className="search-category">
          {searchCategory.map((item, index) => (
            <button
              className={`search-category-item ${item.active ? "active" : ""}`}
              onClick={() => handleSearchCategoryClick(index)}
            >
              <span className="ant-design-icon">{item.icon}</span>
              <span className="search-category-item-text">{item.title}</span>
            </button>
          ))}
        </div>
        <AiSearchInput
          loading={false}
          onSearchSubmit={onSearchSubmit}
          tooltipText="현재 무료로 PRO 기능을 이용중입니다<br>최적화된 답변을 받을 수 있습니다."
        />
      </div>
    </section>
  );
}
