import axios from "axios";
import { aiApiKey } from "../models/apiKey";
import GlobalEnvironments from "../modules/globalEnvironment/GlobalEnvironment";
import { util } from "./util";
import { Cookies } from "../constants/Cookies";
import { Message } from "../models/aiSearchModel";
import { param } from "jquery";

export const aiApi = {
  chatCompletions: async (messages: Array<Message>) => {
    const query = {
      model: "llama-3.1-sonar-large-128k-online",
      max_tokens: 5000,
      messages,
    };

    const response = await fetch("https://api.perplexity.ai/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: aiApiKey,
      },
      body: JSON.stringify(query),
    });

    if (!response.ok) {
      throw new Error("API 요청 실패", { cause: response });
    }

    return response.json();
  },
  getLinkPreview: async (url: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/crawl-og-tags`, {
        params: {
          url,
        },
        ...config,
      })
    ).data;
  },
  postMessage: async ({ ...args }: any) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const data = {
      ...args,
    };
    return (
      await axios.post(
        `${GlobalEnvironments.getApiUrl()}dk_ai/message/`,
        data,
        config
      )
    ).data;
  },
  getMessage: async (seq: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        conversation_id: seq,
      },
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/message`, {
        ...config,
      })
    ).data;
  },

  getConversation: async (type?: "search" | "statement") => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        type,
      },
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/conversation`, {
        ...config,
      })
    ).data;
  },
  postConversastion: async (title: string, type: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const data = {
      title,
      type,
    };
    return (
      await axios.post(
        `${GlobalEnvironments.getApiUrl()}dk_ai/conversation/`,
        data,
        config
      )
    ).data;
  },
  getCompletion: async (message_id: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/completion/`, {
        params: {
          message_id,
        },
        ...config,
      })
    ).data;
  },
  postCompletion: async (completion: any) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const data = {
      ...completion,
    };
    return (
      await axios.post(
        `${GlobalEnvironments.getApiUrl()}dk_ai/completion/`,
        data,
        config
      )
    ).data;
  },

  getStateCompletion: async (request: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const query = {
      query: request,
    };
    return (
      await axios.post(
        `${GlobalEnvironments.getApiUrl()}ai/itemized/search/`,
        query,
        config
      )
    ).data;
  },
  getStateExcel: async (request: {
    itemized_cost_ids: number[];
    price_calculation_ids: number[];
  }) => {
    return (
      await axios.post(
        `${GlobalEnvironments.getApiUrl()}ai/itemized/excel/`,
        request
      )
    ).data;
  },

  getStatement: async (conversation_id: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/statement/`, {
        params: {
          conversation_id,
        },
        ...config,
      })
    ).data;
  },

  postStatement: async (data: {
    content: string;
    role: string;
    conversation_id: string;
    itemized_cost_id?: string;
    itemized_cost_titles?: string;
    price_calculation_id?: string;
    price_calculation_titles?: string;
    itemized_has_project?: string;
    price_has_project?: string;
  }) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return (
      await axios.post(
        `${GlobalEnvironments.getApiUrl()}dk_ai/statement/`,
        {
          content: data.content,
          role: data.role,
          conversation_id: data.conversation_id,
          itemized_cost_id: data.itemized_cost_id?.toString(),
          itemized_cost_titles: data.itemized_cost_titles?.toString(),
          price_calculation_id: data.price_calculation_id?.toString(),
          price_calculation_titles: data.price_calculation_titles?.toString(),
          itemized_has_project: data.itemized_has_project?.toString(),
          price_has_project: data.price_has_project?.toString(),
        },
        config
      )
    ).data;
  },

  getProjectPreview: async (
    id?: string,
    name?: string,
    type?: "price_calculation" | "itemized"
  ) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    const query = {
      item_id: id,
      item_name: name,
      item_type: type,
    };
    return (
      await axios.get(
        `${GlobalEnvironments.getApiUrl()}ai/project/connections`,
        {
          params: query,
          ...config,
        }
      )
    ).data;
  },
  getStatementList: async () => {
    return [
      {
        letter: 1,
        spread: true,
        children: [
          {
            work: "가설공사",
            detail: [
              {
                item: {
                  costContents: [
                    {
                      laborCost: 56874,
                      expenseCost: 3320,
                      materialCost: 14152,
                      countCost: {
                        laborCost: 218396,
                        expenseCost: 12748,
                        materialCost: 54343,
                      },
                      customCost: {
                        laborCost: 0,
                        expenseCost: 0,
                        materialCost: 0,
                      },
                      totalCost: 74346,
                      id: 242804,
                      name: "공기압축기(이동식)",
                      note: "",
                      unit: "시간",
                      standard: "7.1 ㎥/min",
                      type: "",
                      count: 3.84,
                      itemCostRefId: null,
                      mainMaterial: 0,
                      isTotal: 0,
                      isRawValue: 0,
                      value: {
                        laborCost: 56874,
                        expenseCost: 3320,
                        materialCost: 14152,
                        id: 203939,
                        machinePrice: {
                          details: [
                            {
                              reasons: [
                                {
                                  id: 3985,
                                  value: "19314000",
                                  unit: "￦",
                                  operator: "",
                                  exchange: null,
                                  dollarPriceId: null,
                                  valueNumber: 19314000,
                                  visible: true,
                                },
                                {
                                  id: 3986,
                                  value: "1719",
                                  unit: "",
                                  operator: "×",
                                  exchange: null,
                                  dollarPriceId: null,
                                  valueNumber: 1719,
                                  visible: false,
                                },
                                {
                                  id: 3987,
                                  value: "10(-7)",
                                  unit: "",
                                  operator: "×",
                                  exchange: null,
                                  dollarPriceId: null,
                                  valueNumber: 1e-7,
                                  visible: true,
                                },
                              ],
                              id: 1555,
                              name: "손    료",
                              standard: "",
                              priceType: "경비",
                              unit: null,
                              note: null,
                              value: 3320,
                            },
                            {
                              reasons: [
                                {
                                  id: 3988,
                                  value: "10",
                                  unit: "ℓ",
                                  operator: "",
                                  exchange: null,
                                  dollarPriceId: null,
                                  valueNumber: 10,
                                  visible: false,
                                },
                                {
                                  id: 3989,
                                  value: null,
                                  unit: "",
                                  operator: "×",
                                  material: {
                                    id: 157327,
                                    name: "경    유 ",
                                    note: "",
                                    unit: "ℓ",
                                    division: "일반",
                                    standard: "",
                                    applyDate: "2024-12-26",
                                    applyPrice: 1220,
                                    applyMethod: "최저단가",
                                    priceInfoList: [
                                      {
                                        id: 495423,
                                        kind: "가격정보",
                                        page: "2024.12.3(조달청)",
                                        price: 1220,
                                        created: "2024-12-10T08:04:10.387Z",
                                      },
                                    ],
                                  },
                                  exchange: null,
                                  dollarPriceId: null,
                                  valueNumber: 1220,
                                  visible: true,
                                },
                              ],
                              id: 1556,
                              name: "주연료",
                              standard: "경유",
                              priceType: "재료비",
                              unit: "ℓ",
                              note: null,
                              value: 12200,
                            },
                            {
                              reasons: [
                                {
                                  id: 3990,
                                  value: "16",
                                  unit: "%",
                                  operator: "",
                                  exchange: null,
                                  dollarPriceId: null,
                                  valueNumber: 16,
                                  visible: false,
                                },
                                {
                                  id: 3991,
                                  value: null,
                                  unit: "",
                                  operator: "×",
                                  exchange: null,
                                  dollarPriceId: null,
                                  valueNumber: 0,
                                  visible: true,
                                },
                              ],
                              id: 1557,
                              name: "잡    품",
                              standard: "주연료의",
                              priceType: "재료비",
                              unit: "%",
                              note: null,
                              value: 1952,
                            },
                            {
                              reasons: [
                                {
                                  id: 3992,
                                  value: "1",
                                  unit: "인",
                                  operator: "",
                                  exchange: null,
                                  dollarPriceId: null,
                                  valueNumber: 1,
                                  visible: false,
                                },
                                {
                                  id: 3993,
                                  value: null,
                                  unit: "",
                                  operator: "×",
                                  labor: {
                                    id: 51,
                                    name: "건설기계운전사",
                                    note: "2024년(하)",
                                    unit: "인",
                                    standard: "",
                                    applyDate: "2024-12-26",
                                    applyPrice: 272996,
                                    applyMethod: "최저단가",
                                    nameInitial: "ㄱㅅㄱㄱㅇㅈㅅ",
                                    priceInfoList: [
                                      {
                                        id: 51,
                                        kind: "최저단가",
                                        price: 272996,
                                      },
                                    ],
                                    nameRemoveSpace: "건설기계운전사",
                                  },
                                  exchange: null,
                                  dollarPriceId: null,
                                  valueNumber: 272996,
                                  visible: true,
                                },
                                {
                                  id: 3994,
                                  value: "1/8*16/12*25/20",
                                  unit: "",
                                  operator: "×",
                                  exchange: null,
                                  dollarPriceId: null,
                                  valueNumber: 0.208333333,
                                  visible: true,
                                },
                              ],
                              id: 1558,
                              name: "건설기계운전사",
                              standard: "",
                              priceType: "노무비",
                              unit: "인",
                              note: null,
                              value: 56874,
                            },
                          ],
                          laborCost: 56874,
                          expenseCost: 3320,
                          materialCost: 14152,
                          id: 468,
                          name: "공기 압축기",
                          standard: "7.1㎥/min",
                        },
                        priceCalculation: null,
                        type: "",
                        value: null,
                      },
                    },
                    {
                      laborCost: 212500,
                      expenseCost: 0,
                      materialCost: 0,
                      countCost: {
                        laborCost: 199750,
                        expenseCost: 0,
                        materialCost: 0,
                      },
                      customCost: {
                        laborCost: 0,
                        expenseCost: 0,
                        materialCost: 0,
                      },
                      totalCost: 212500,
                      id: 242805,
                      name: "착암공",
                      note: "",
                      unit: "인",
                      standard: "",
                      type: "",
                      count: 0.94,
                      itemCostRefId: null,
                      mainMaterial: 0,
                      isTotal: 0,
                      isRawValue: 0,
                      value: {
                        laborCost: 212500,
                        expenseCost: 0,
                        materialCost: 0,
                        id: 203940,
                        labor: {
                          priceInfoList: [
                            {
                              id: 17,
                              kind: "최저단가",
                              price: 212500,
                            },
                          ],
                          id: 17,
                          name: "착 암 공",
                          standard: "",
                          unit: "인",
                          note: "2024년(하)",
                          applyDate: "2024-12-26",
                          applyMethod: "최저단가",
                          nameInitial: "ㅊㅇㄱ",
                          nameRemoveSpace: "착암공",
                          applyPrice: 212500,
                        },
                        priceCalculation: null,
                        type: "노무비",
                        value: null,
                      },
                    },
                    {
                      laborCost: 167081,
                      expenseCost: 0,
                      materialCost: 0,
                      countCost: {
                        laborCost: 80198,
                        expenseCost: 0,
                        materialCost: 0,
                      },
                      customCost: {
                        laborCost: 0,
                        expenseCost: 0,
                        materialCost: 0,
                      },
                      totalCost: 167081,
                      id: 242806,
                      name: "보통인부",
                      note: "",
                      unit: "인",
                      standard: "",
                      type: "",
                      count: 0.48,
                      itemCostRefId: null,
                      mainMaterial: 0,
                      isTotal: 0,
                      isRawValue: 0,
                      value: {
                        laborCost: 167081,
                        expenseCost: 0,
                        materialCost: 0,
                        id: 203941,
                        labor: {
                          priceInfoList: [
                            {
                              id: 2,
                              kind: "최저단가",
                              price: 167081,
                            },
                          ],
                          id: 2,
                          name: "보통인부",
                          standard: "",
                          unit: "인",
                          note: "2024년(하)",
                          applyDate: "2024-12-26",
                          applyMethod: "최저단가",
                          nameInitial: "ㅂㅌㅇㅂ",
                          nameRemoveSpace: "보통인부",
                          applyPrice: 167081,
                        },
                        priceCalculation: null,
                        type: "노무비",
                        value: null,
                      },
                    },
                    {
                      laborCost: 0,
                      expenseCost: 463,
                      materialCost: 0,
                      countCost: {
                        laborCost: 0,
                        expenseCost: 7222,
                        materialCost: 0,
                      },
                      customCost: {
                        laborCost: 0,
                        expenseCost: 0,
                        materialCost: 0,
                      },
                      totalCost: 463,
                      id: 242807,
                      name: "소형브레이커(공압식)",
                      note: "",
                      unit: "시간",
                      standard: "1.3 ㎥/min",
                      type: "",
                      count: 15.6,
                      itemCostRefId: null,
                      mainMaterial: 0,
                      isTotal: 0,
                      isRawValue: 0,
                      value: {
                        laborCost: 0,
                        expenseCost: 463,
                        materialCost: 0,
                        id: 203942,
                        machinePrice: {
                          details: [
                            {
                              reasons: [
                                {
                                  id: 4038,
                                  value: "1853000",
                                  unit: "￦",
                                  operator: "",
                                  exchange: null,
                                  dollarPriceId: null,
                                  valueNumber: 1853000,
                                  visible: true,
                                },
                                {
                                  id: 4039,
                                  value: "2500",
                                  unit: "",
                                  operator: "×",
                                  exchange: null,
                                  dollarPriceId: null,
                                  valueNumber: 2500,
                                  visible: false,
                                },
                                {
                                  id: 4040,
                                  value: "10(-7)",
                                  unit: "",
                                  operator: "×",
                                  exchange: null,
                                  dollarPriceId: null,
                                  valueNumber: 1e-7,
                                  visible: true,
                                },
                              ],
                              id: 1576,
                              name: "손    료",
                              standard: "",
                              priceType: "경비",
                              unit: null,
                              note: null,
                              value: 463,
                            },
                          ],
                          laborCost: 0,
                          expenseCost: 463,
                          materialCost: 0,
                          id: 474,
                          name: "소형브레이커(공압식)",
                          standard: "1.3㎥/min",
                        },
                        priceCalculation: null,
                        type: "",
                        value: null,
                      },
                    },
                    {
                      laborCost: 0,
                      expenseCost: 0,
                      materialCost: 498344,
                      countCost: {
                        laborCost: 0,
                        expenseCost: 0,
                        materialCost: 4983,
                      },
                      customCost: {
                        laborCost: 0,
                        expenseCost: 0,
                        materialCost: 0,
                      },
                      totalCost: 0,
                      id: 242808,
                      name: "잡 재 료",
                      note: "",
                      unit: "%",
                      standard: "노무비의",
                      type: "재료비",
                      count: 1,
                      itemCostRefId: null,
                      mainMaterial: 0,
                      isTotal: 0,
                      isRawValue: 0,
                    },
                  ],
                  laborCost: 498344,
                  expenseCost: 19970,
                  materialCost: 59326,
                  id: 68610,
                  name: "인력굴착(암반)",
                  standard: "경암",
                  unit: "㎥",
                  note: "공통 3-1-3",
                  memo: "",
                },
                name: "인력굴착(암반)",
                note: "[일위] 제 1호표",
                type: "itemize",
                unit: "㎥",
                origin: true,
                formula: "1",
                standard: "경암",
                laborCost: 0,
                count_value: 1,
                expenseCost: 0,
                newInChange: true,
                ready_count: [],
                applyPercent: true,
                materialCost: 0,
                ready_formula: [],
              },
              {
                item: {
                  costContents: [
                    {
                      laborCost: 0,
                      expenseCost: 0,
                      materialCost: 0,
                      countCost: {
                        laborCost: 0,
                        expenseCost: 0,
                        materialCost: 0,
                      },
                      customCost: {
                        laborCost: 0,
                        expenseCost: 0,
                        materialCost: 0,
                      },
                      totalCost: 0,
                      id: 256440,
                      name: "내산페인트",
                      note: "",
                      unit: "ℓ",
                      standard: "",
                      type: "재료비",
                      count: 0.255,
                      itemCostRefId: null,
                      mainMaterial: 0,
                      isTotal: 0,
                      isRawValue: 0,
                      value: {
                        laborCost: 0,
                        expenseCost: 0,
                        materialCost: 0,
                        id: 214860,
                        material: {
                          priceInfoList: [
                            {
                              id: 411395,
                              kind: "견적가1",
                              price: 0,
                              page: "",
                              created: "2024-09-15T16:10:37.195Z",
                            },
                          ],
                          id: 175105,
                          name: "내산페인트",
                          standard: "",
                          unit: "ℓ",
                          note: "",
                          division: "견적",
                          applyDate: "2024-12-26",
                          applyMethod: "최저단가",
                          applyPrice: 0,
                        },
                        priceCalculation: null,
                        type: "재료비",
                        value: null,
                      },
                    },
                    {
                      laborCost: 253409,
                      expenseCost: 0,
                      materialCost: 0,
                      countCost: {
                        laborCost: 13684,
                        expenseCost: 0,
                        materialCost: 0,
                      },
                      customCost: {
                        laborCost: 0,
                        expenseCost: 0,
                        materialCost: 0,
                      },
                      totalCost: 253409,
                      id: 256441,
                      name: "도장공",
                      note: "",
                      unit: "인",
                      standard: "",
                      type: "",
                      count: 0.054,
                      itemCostRefId: null,
                      mainMaterial: 0,
                      isTotal: 0,
                      isRawValue: 0,
                      value: {
                        laborCost: 253409,
                        expenseCost: 0,
                        materialCost: 0,
                        id: 214861,
                        labor: {
                          priceInfoList: [
                            {
                              id: 31,
                              kind: "최저단가",
                              price: 253409,
                            },
                          ],
                          id: 31,
                          name: "도 장 공",
                          standard: "",
                          unit: "인",
                          note: "2025년(상)",
                          applyDate: "2024-12-26",
                          applyMethod: "최저단가",
                          nameInitial: "ㄷㅈㄱ",
                          nameRemoveSpace: "도장공",
                          applyPrice: 253409,
                        },
                        priceCalculation: null,
                        type: "노무비",
                        value: null,
                      },
                    },
                  ],
                  laborCost: 13684,
                  expenseCost: 0,
                  materialCost: 0,
                  id: 72497,
                  name: "난방설비 페인트칠(파이프, 탱크, 덕트의 내상용)",
                  standard: "1회칠",
                  unit: "㎡",
                  note: "",
                  memo: "",
                },
                name: "난방설비 페인트칠(파이프, 탱크, 덕트의 내상용)",
                note: "[일위] 제 2호표",
                type: "itemize",
                unit: "㎡",
                origin: true,
                formula: "1",
                standard: "1회칠",
                laborCost: 0,
                count_value: 1,
                expenseCost: 0,
                newInChange: true,
                ready_count: [],
                applyPercent: true,
                materialCost: 0,
                ready_formula: [],
              },
              {
                item: {
                  costContents: [
                    {
                      laborCost: 277894,
                      expenseCost: 0,
                      materialCost: 0,
                      countCost: {
                        laborCost: 2778,
                        expenseCost: 0,
                        materialCost: 0,
                      },
                      customCost: {
                        laborCost: 0,
                        expenseCost: 0,
                        materialCost: 0,
                      },
                      totalCost: 277894,
                      id: 253160,
                      name: "건축목공",
                      note: "",
                      unit: "인",
                      standard: "",
                      type: "",
                      count: 0.01,
                      itemCostRefId: null,
                      mainMaterial: 0,
                      isTotal: 0,
                      isRawValue: 0,
                      value: {
                        laborCost: 277894,
                        expenseCost: 0,
                        materialCost: 0,
                        id: 212159,
                        labor: {
                          priceInfoList: [
                            {
                              id: 25,
                              kind: "최저단가",
                              price: 277894,
                            },
                          ],
                          id: 25,
                          name: "건축목공",
                          standard: "",
                          unit: "인",
                          note: "2025년(상)",
                          applyDate: "2024-12-26",
                          applyMethod: "최저단가",
                          nameInitial: "ㄱㅊㅁㄱ",
                          nameRemoveSpace: "건축목공",
                          applyPrice: 277894,
                        },
                        priceCalculation: null,
                        type: "노무비",
                        value: null,
                      },
                    },
                    {
                      laborCost: 167081,
                      expenseCost: 0,
                      materialCost: 0,
                      countCost: {
                        laborCost: 3341,
                        expenseCost: 0,
                        materialCost: 0,
                      },
                      customCost: {
                        laborCost: 0,
                        expenseCost: 0,
                        materialCost: 0,
                      },
                      totalCost: 167081,
                      id: 253161,
                      name: "보통인부",
                      note: "",
                      unit: "인",
                      standard: "",
                      type: "",
                      count: 0.02,
                      itemCostRefId: null,
                      mainMaterial: 0,
                      isTotal: 0,
                      isRawValue: 0,
                      value: {
                        laborCost: 167081,
                        expenseCost: 0,
                        materialCost: 0,
                        id: 212160,
                        labor: {
                          priceInfoList: [
                            {
                              id: 2,
                              kind: "최저단가",
                              price: 167081,
                            },
                          ],
                          id: 2,
                          name: "보통인부",
                          standard: "",
                          unit: "인",
                          note: "2024년(하)",
                          applyDate: "2024-12-26",
                          applyMethod: "최저단가",
                          nameInitial: "ㅂㅌㅇㅂ",
                          nameRemoveSpace: "보통인부",
                          applyPrice: 167081,
                        },
                        priceCalculation: null,
                        type: "노무비",
                        value: null,
                      },
                    },
                  ],
                  laborCost: 6119,
                  expenseCost: 0,
                  materialCost: 0,
                  id: 71152,
                  name: "건축물 구조체별 철거 (벽)",
                  standard: "텍스·합판",
                  unit: "㎡",
                  note: "",
                  memo: "",
                },
                name: "건축물 구조체별 철거 (벽)",
                note: "[일위] 제 3호표",
                type: "itemize",
                unit: "㎡",
                origin: true,
                formula: "1",
                standard: "텍스·합판",
                laborCost: 0,
                count_value: 1,
                expenseCost: 0,
                newInChange: true,
                ready_count: [],
                applyPercent: true,
                materialCost: 0,
                ready_formula: [],
              },
              {
                item: {
                  subtitles: [
                    {
                      subtitles: [],
                      machinePriceLaborCost: 0,
                      machinePriceMaterialCost: 0,
                      machinePriceExpenseCost: 0,
                      pcMaterialCost: 0,
                      pcExpenseCost: 0,
                      pcLaborCost: 0,
                      laborCost: 0,
                      expenseCost: 0,
                      materialCost: 51041,
                      labors: [],
                      materials: [
                        {
                          explain: "*0.44*A",
                          type: "재료비",
                          validation: true,
                          value: 1336,
                          material: {
                            priceInfoList: [
                              {
                                id: 512668,
                                kind: "거래가격",
                                price: 4340,
                                page: "744(24.11)",
                                created: "2024-12-10T10:03:26.990Z",
                              },
                              {
                                id: 512667,
                                kind: "물가자료",
                                price: 4340,
                                page: "502(24.11)",
                                created: "2024-12-10T10:03:26.883Z",
                              },
                              {
                                id: 512669,
                                kind: "유통물가",
                                price: 4340,
                                page: "507(24.11)",
                                created: "2024-12-10T10:03:27.078Z",
                              },
                            ],
                            id: 166929,
                            name: "베이스찬넬",
                            standard: "20×90×70×2.3t",
                            unit: "M",
                            note: "",
                            division: "일반",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            applyPrice: 4340,
                          },
                        },
                        {
                          explain: "*0.44*A",
                          type: "재료비",
                          validation: true,
                          value: 1164,
                          material: {
                            priceInfoList: [
                              {
                                id: 522208,
                                kind: "거래가격",
                                price: 3780,
                                page: "744(24.11)",
                                created: "2024-12-10T10:59:54.504Z",
                              },
                              {
                                id: 522207,
                                kind: "물가자료",
                                price: 3780,
                                page: "502(24.11)",
                                created: "2024-12-10T10:59:54.411Z",
                              },
                              {
                                id: 522209,
                                kind: "유통물가",
                                price: 3780,
                                page: "507(24.11)",
                                created: "2024-12-10T10:59:54.590Z",
                              },
                            ],
                            id: 173469,
                            name: "톱 찬 넬",
                            standard: "20×40×70×2.3t",
                            unit: "M",
                            note: "",
                            division: "일반",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            applyPrice: 3780,
                          },
                        },
                        {
                          explain: "*0.2*A",
                          type: "재료비",
                          validation: true,
                          value: 6720,
                          material: {
                            priceInfoList: [
                              {
                                id: 517102,
                                kind: "거래가격",
                                price: 48000,
                                page: "744(24.11)",
                                created: "2024-12-10T10:32:51.953Z",
                              },
                              {
                                id: 517101,
                                kind: "물가자료",
                                price: 48000,
                                page: "502(24.11)",
                                created: "2024-12-10T10:32:51.871Z",
                              },
                              {
                                id: 517103,
                                kind: "유통물가",
                                price: 48000,
                                page: "507(24.11)",
                                created: "2024-12-10T10:32:52.042Z",
                              },
                            ],
                            id: 170375,
                            name: "외부판넬 (벽)",
                            standard: "1200×2400",
                            unit: "매",
                            note: "",
                            division: "일반",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            applyPrice: 48000,
                          },
                        },
                        {
                          explain: "*0.12*A",
                          type: "재료비",
                          validation: true,
                          value: 7308,
                          material: {
                            priceInfoList: [
                              {
                                id: 517105,
                                kind: "거래가격",
                                price: 87000,
                                page: "744(24.11)",
                                created: "2024-12-10T10:32:52.531Z",
                              },
                              {
                                id: 517104,
                                kind: "물가자료",
                                price: 87000,
                                page: "502(24.11)",
                                created: "2024-12-10T10:32:52.445Z",
                              },
                              {
                                id: 517106,
                                kind: "유통물가",
                                price: 87000,
                                page: "507(24.11)",
                                created: "2024-12-10T10:32:52.614Z",
                              },
                            ],
                            id: 170376,
                            name: "외부판넬 (창문)",
                            standard: "900×1200",
                            unit: "매",
                            note: "",
                            division: "일반",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            applyPrice: 87000,
                          },
                        },
                        {
                          explain: "*0.03*A",
                          type: "재료비",
                          validation: true,
                          value: 3896,
                          material: {
                            priceInfoList: [
                              {
                                id: 517108,
                                kind: "거래가격",
                                price: 188550,
                                page: "744(24.11)",
                                created: "2024-12-10T10:32:53.045Z",
                              },
                              {
                                id: 517107,
                                kind: "물가자료",
                                price: 185550,
                                page: "502(24.11)",
                                created: "2024-12-10T10:32:52.964Z",
                              },
                              {
                                id: 517109,
                                kind: "유통물가",
                                price: 185550,
                                page: "507(24.11)",
                                created: "2024-12-10T10:32:53.134Z",
                              },
                            ],
                            id: 170377,
                            name: "외부판넬 (철재문)",
                            standard: "1200×2400",
                            unit: "매",
                            note: "",
                            division: "일반",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            applyPrice: 185550,
                          },
                        },
                        {
                          explain: "*0.15*A",
                          type: "재료비",
                          validation: true,
                          value: 3937,
                          material: {
                            priceInfoList: [
                              {
                                id: 507695,
                                kind: "거래가격",
                                price: 37500,
                                page: "744(24.11)",
                                created: "2024-12-10T09:36:26.557Z",
                              },
                              {
                                id: 507694,
                                kind: "물가자료",
                                price: 37500,
                                page: "502(24.11)",
                                created: "2024-12-10T09:36:26.479Z",
                              },
                              {
                                id: 507696,
                                kind: "유통물가",
                                price: 37500,
                                page: "507(24.11)",
                                created: "2024-12-10T09:36:26.638Z",
                              },
                            ],
                            id: 163840,
                            name: "내부판넬 (벽)",
                            standard: "1200×2400",
                            unit: "매",
                            note: "",
                            division: "일반",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            applyPrice: 37500,
                          },
                        },
                        {
                          explain: "*0.05*A",
                          type: "재료비",
                          validation: true,
                          value: 4987,
                          material: {
                            priceInfoList: [
                              {
                                id: 507692,
                                kind: "거래가격",
                                price: 142500,
                                page: "744(24.11)",
                                created: "2024-12-10T09:36:26.009Z",
                              },
                              {
                                id: 507691,
                                kind: "물가자료",
                                price: 142500,
                                page: "502(24.11)",
                                created: "2024-12-10T09:36:25.928Z",
                              },
                              {
                                id: 507693,
                                kind: "유통물가",
                                price: 142500,
                                page: "507(24.11)",
                                created: "2024-12-10T09:36:26.088Z",
                              },
                            ],
                            id: 163839,
                            name: "내부판넬 (목재문)",
                            standard: "1200×2400",
                            unit: "매",
                            note: "",
                            division: "일반",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            applyPrice: 142500,
                          },
                        },
                        {
                          explain: "*0.31*A",
                          type: "재료비",
                          validation: true,
                          value: 1302,
                          material: {
                            priceInfoList: [
                              {
                                id: 491671,
                                kind: "유통물가",
                                price: 6000,
                                page: "517(21.8)",
                                created: "2024-10-10T15:16:48.000Z",
                              },
                            ],
                            id: 173627,
                            name: "판넬조인트",
                            standard: "L=2400",
                            unit: "조",
                            note: "",
                            division: "일반",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            applyPrice: 6000,
                          },
                        },
                        {
                          explain: "*0.03*A",
                          type: "재료비",
                          validation: true,
                          value: 850,
                          material: {
                            priceInfoList: [
                              {
                                id: 521189,
                                kind: "거래가격",
                                price: 40500,
                                page: "744(24.11)",
                                created: "2024-12-10T10:53:49.074Z",
                              },
                              {
                                id: 521188,
                                kind: "물가자료",
                                price: 40500,
                                page: "502(24.11)",
                                created: "2024-12-10T10:53:48.995Z",
                              },
                              {
                                id: 521190,
                                kind: "유통물가",
                                price: 40500,
                                page: "507(24.11)",
                                created: "2024-12-10T10:53:49.156Z",
                              },
                            ],
                            id: 172761,
                            name: "출입구채양 (CANOPY)",
                            standard: "L=1200",
                            unit: "개",
                            note: "",
                            division: "일반",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            applyPrice: 40500,
                          },
                        },
                        {
                          explain: "*0.02*A",
                          type: "재료비",
                          validation: true,
                          value: 55,
                          material: {
                            priceInfoList: [
                              {
                                id: 511607,
                                kind: "거래가격",
                                price: 3980,
                                page: "744(24.11)",
                                created: "2024-12-10T09:58:40.600Z",
                              },
                              {
                                id: 511606,
                                kind: "물가자료",
                                price: 3980,
                                page: "502(24.11)",
                                created: "2024-12-10T09:58:40.523Z",
                              },
                              {
                                id: 511608,
                                kind: "유통물가",
                                price: 3980,
                                page: "507(24.11)",
                                created: "2024-12-10T09:58:40.685Z",
                              },
                            ],
                            id: 166433,
                            name: "박공판넬",
                            standard: "L=2400",
                            unit: "M",
                            note: "",
                            division: "일반",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            applyPrice: 3980,
                          },
                        },
                        {
                          explain: "*1.23*A",
                          type: "재료비",
                          validation: true,
                          value: 7413,
                          material: {
                            priceInfoList: [
                              {
                                id: 520693,
                                kind: "거래가격",
                                price: 8610,
                                page: "744(24.11)",
                                created: "2024-12-10T10:50:48.041Z",
                              },
                              {
                                id: 520692,
                                kind: "물가자료",
                                price: 8610,
                                page: "502(24.11)",
                                created: "2024-12-10T10:50:47.956Z",
                              },
                              {
                                id: 520694,
                                kind: "유통물가",
                                price: 8610,
                                page: "507(24.11)",
                                created: "2024-12-10T10:50:48.126Z",
                              },
                            ],
                            id: 172425,
                            name: "지붕판 (ROOF SHEET)",
                            standard: "0.5T, 칼라C/S",
                            unit: "㎡",
                            note: "",
                            division: "일반",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            applyPrice: 8610,
                          },
                        },
                        {
                          explain: "*0.07*A",
                          type: "재료비",
                          validation: true,
                          value: 3961,
                          material: {
                            priceInfoList: [
                              {
                                id: 522233,
                                kind: "거래가격",
                                price: 108000,
                                page: "744(24.11)",
                                created: "2024-12-10T11:00:14.042Z",
                              },
                              {
                                id: 522232,
                                kind: "물가자료",
                                price: 108000,
                                page: "502(24.11)",
                                created: "2024-12-10T11:00:13.963Z",
                              },
                              {
                                id: 491339,
                                kind: "유통물가",
                                price: 80850,
                                page: "508(23.12)",
                                created: "2024-10-10T15:15:44.868Z",
                              },
                            ],
                            id: 173504,
                            name: "트러스 (TRUSS)",
                            standard: "SPAN:L=7.2",
                            unit: "개",
                            note: "",
                            division: "일반",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            applyPrice: 80850,
                          },
                        },
                        {
                          explain: "*1.52*A",
                          type: "재료비",
                          validation: true,
                          value: 1755,
                          material: {
                            priceInfoList: [
                              {
                                id: 520663,
                                kind: "거래가격",
                                price: 1650,
                                page: "744(24.11)",
                                created: "2024-12-10T10:50:32.470Z",
                              },
                              {
                                id: 520662,
                                kind: "물가자료",
                                price: 1650,
                                page: "502(24.11)",
                                created: "2024-12-10T10:50:32.392Z",
                              },
                              {
                                id: 520664,
                                kind: "유통물가",
                                price: 1650,
                                page: "507(24.11)",
                                created: "2024-12-10T10:50:32.551Z",
                              },
                            ],
                            id: 172391,
                            name: "중도리 (PURLIN)",
                            standard: "60×30×10×2.3t",
                            unit: "M",
                            note: "",
                            division: "일반",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            applyPrice: 1650,
                          },
                        },
                        {
                          explain: "*0.69*A",
                          type: "재료비",
                          validation: true,
                          value: 5361,
                          material: {
                            priceInfoList: [
                              {
                                id: 520879,
                                kind: "거래가격",
                                price: 11100,
                                page: "744(24.11)",
                                created: "2024-12-10T10:52:18.047Z",
                              },
                              {
                                id: 520878,
                                kind: "물가자료",
                                price: 11100,
                                page: "502(24.11)",
                                created: "2024-12-10T10:52:17.967Z",
                              },
                              {
                                id: 520880,
                                kind: "유통물가",
                                price: 11100,
                                page: "507(24.11)",
                                created: "2024-12-10T10:52:18.130Z",
                              },
                            ],
                            id: 172597,
                            name: "천정판 (CEILING)",
                            standard: "1200×1200",
                            unit: "매",
                            note: "",
                            division: "일반",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            applyPrice: 11100,
                          },
                        },
                        {
                          explain: "*1.53*A",
                          type: "재료비",
                          validation: true,
                          value: 996,
                          material: {
                            priceInfoList: [
                              {
                                id: 504108,
                                kind: "거래가격",
                                price: 930,
                                page: "744(24.11)",
                                created: "2024-12-10T09:16:39.245Z",
                              },
                              {
                                id: 504107,
                                kind: "물가자료",
                                price: 930,
                                page: "502(24.11)",
                                created: "2024-12-10T09:16:39.167Z",
                              },
                              {
                                id: 504109,
                                kind: "유통물가",
                                price: 930,
                                page: "507(24.11)",
                                created: "2024-12-10T09:16:39.328Z",
                              },
                            ],
                            id: 161577,
                            name: "T-BAR",
                            standard: "L=1200mm",
                            unit: "개",
                            note: "",
                            division: "일반",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            applyPrice: 930,
                          },
                        },
                      ],
                      contents: [],
                      variables: [
                        {
                          name: "A",
                          explain: "0.70",
                          openInput: 0,
                          operator: "",
                          operatorCheck: 0,
                          unit: "",
                          validation: true,
                          operatorKind: "합계에",
                          note: "",
                          value: 0.7,
                        },
                      ],
                      id: 9686,
                      name: "재료비",
                      note: null,
                      standard: null,
                    },
                    {
                      subtitles: [],
                      machinePriceLaborCost: 0,
                      machinePriceMaterialCost: 0,
                      machinePriceExpenseCost: 0,
                      pcMaterialCost: 0,
                      pcExpenseCost: 0,
                      pcLaborCost: 0,
                      laborCost: 0,
                      expenseCost: 0,
                      materialCost: 4593,
                      labors: [],
                      materials: [],
                      contents: [
                        {
                          name: " ",
                          valueType: "upper-material",
                          value: 4593,
                          contentValue: "상위 항목 재료비의",
                          percent: 9,
                          type: "재료비",
                        },
                      ],
                      variables: [],
                      id: 9687,
                      name: "부자재",
                      note: null,
                      standard: null,
                    },
                    {
                      subtitles: [],
                      machinePriceLaborCost: 0,
                      machinePriceMaterialCost: 0,
                      machinePriceExpenseCost: 0,
                      pcMaterialCost: 0,
                      pcExpenseCost: 0,
                      pcLaborCost: 0,
                      laborCost: 90630,
                      expenseCost: 0,
                      materialCost: 0,
                      labors: [
                        {
                          explain: "*0.26",
                          validation: true,
                          value: 72252,
                          labor: {
                            priceInfoList: [
                              {
                                id: 25,
                                kind: "최저단가",
                                price: 277894,
                              },
                            ],
                            id: 25,
                            name: "건축목공",
                            standard: "",
                            unit: "인",
                            note: "2025년(상)",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            nameInitial: "ㄱㅊㅁㄱ",
                            nameRemoveSpace: "건축목공",
                            applyPrice: 277894,
                          },
                        },
                        {
                          explain: "*0.11",
                          validation: true,
                          value: 18378,
                          labor: {
                            priceInfoList: [
                              {
                                id: 2,
                                kind: "최저단가",
                                price: 167081,
                              },
                            ],
                            id: 2,
                            name: "보통인부",
                            standard: "",
                            unit: "인",
                            note: "2024년(하)",
                            applyDate: "2024-12-26",
                            applyMethod: "최저단가",
                            nameInitial: "ㅂㅌㅇㅂ",
                            nameRemoveSpace: "보통인부",
                            applyPrice: 167081,
                          },
                        },
                      ],
                      materials: [],
                      contents: [],
                      variables: [],
                      id: 9688,
                      name: "노무비",
                      note: null,
                      standard: null,
                    },
                    {
                      subtitles: [],
                      machinePriceLaborCost: 10806,
                      machinePriceMaterialCost: 1224,
                      machinePriceExpenseCost: 5850,
                      pcMaterialCost: 0,
                      pcExpenseCost: 0,
                      pcLaborCost: 0,
                      laborCost: 10806,
                      expenseCost: 5850,
                      materialCost: 1224,
                      labors: [],
                      materials: [],
                      contents: [],
                      variables: [
                        {
                          name: "n",
                          explain: "0.19",
                          openInput: 0,
                          operator: "*",
                          operatorCheck: 1,
                          unit: "",
                          validation: true,
                          operatorKind: "합계에",
                          note: "",
                          value: 0.19,
                        },
                      ],
                      id: 9689,
                      name: "크레인(타이어)",
                      note: null,
                      standard: "10Ton",
                      machinePrice: {
                        details: [
                          {
                            reasons: [
                              {
                                id: 2355,
                                value: "134000000",
                                unit: "￦",
                                operator: "",
                                exchange: null,
                                dollarPriceId: null,
                                valueNumber: 134000000,
                                visible: true,
                              },
                              {
                                id: 2356,
                                value: "2298",
                                unit: "",
                                operator: "×",
                                exchange: null,
                                dollarPriceId: null,
                                valueNumber: 2298,
                                visible: false,
                              },
                              {
                                id: 2357,
                                value: "10(-7)",
                                unit: "",
                                operator: "×",
                                exchange: null,
                                dollarPriceId: null,
                                valueNumber: 1e-7,
                                visible: true,
                              },
                            ],
                            id: 928,
                            name: "손    료",
                            standard: "",
                            priceType: "경비",
                            unit: null,
                            note: null,
                            value: 30793,
                          },
                          {
                            reasons: [
                              {
                                id: 2358,
                                value: "3.8",
                                unit: "ℓ",
                                operator: "",
                                exchange: null,
                                dollarPriceId: null,
                                valueNumber: 3.8,
                                visible: false,
                              },
                              {
                                id: 2359,
                                value: null,
                                unit: "",
                                operator: "×",
                                material: {
                                  id: 157327,
                                  name: "경    유 ",
                                  note: "",
                                  unit: "ℓ",
                                  division: "일반",
                                  standard: "",
                                  applyDate: "2024-12-26",
                                  applyPrice: 1220,
                                  applyMethod: "최저단가",
                                  priceInfoList: [
                                    {
                                      id: 495423,
                                      kind: "가격정보",
                                      page: "2024.12.3(조달청)",
                                      price: 1220,
                                      created: "2024-12-10T08:04:10.387Z",
                                    },
                                  ],
                                },
                                exchange: null,
                                dollarPriceId: null,
                                valueNumber: 1220,
                                visible: true,
                              },
                            ],
                            id: 929,
                            name: "주연료",
                            standard: "경유",
                            priceType: "재료비",
                            unit: "ℓ",
                            note: null,
                            value: 4636,
                          },
                          {
                            reasons: [
                              {
                                id: 2360,
                                value: "39",
                                unit: "%",
                                operator: "",
                                exchange: null,
                                dollarPriceId: null,
                                valueNumber: 39,
                                visible: false,
                              },
                              {
                                id: 2361,
                                value: null,
                                unit: "",
                                operator: "×",
                                exchange: null,
                                dollarPriceId: null,
                                valueNumber: 0,
                                visible: true,
                              },
                            ],
                            id: 930,
                            name: "잡    품",
                            standard: "주연료의",
                            priceType: "재료비",
                            unit: "%",
                            note: null,
                            value: 1808,
                          },
                          {
                            reasons: [
                              {
                                id: 2362,
                                value: "1",
                                unit: "인",
                                operator: "",
                                exchange: null,
                                dollarPriceId: null,
                                valueNumber: 1,
                                visible: false,
                              },
                              {
                                id: 2363,
                                value: null,
                                unit: "",
                                operator: "×",
                                labor: {
                                  id: 51,
                                  name: "건설기계운전사",
                                  note: "2024년(하)",
                                  unit: "인",
                                  standard: "",
                                  applyDate: "2024-12-26",
                                  applyPrice: 272996,
                                  applyMethod: "최저단가",
                                  nameInitial: "ㄱㅅㄱㄱㅇㅈㅅ",
                                  priceInfoList: [
                                    {
                                      id: 51,
                                      kind: "최저단가",
                                      price: 272996,
                                    },
                                  ],
                                  nameRemoveSpace: "건설기계운전사",
                                },
                                exchange: null,
                                dollarPriceId: null,
                                valueNumber: 272996,
                                visible: true,
                              },
                              {
                                id: 2364,
                                value: "1/8*16/12*25/20",
                                unit: "",
                                operator: "×",
                                exchange: null,
                                dollarPriceId: null,
                                valueNumber: 0.208333333,
                                visible: true,
                              },
                            ],
                            id: 931,
                            name: "건설기계운전사",
                            standard: "",
                            priceType: "노무비",
                            unit: "인",
                            note: null,
                            value: 56874,
                          },
                        ],
                        laborCost: 56874,
                        expenseCost: 30793,
                        materialCost: 6444,
                        id: 241,
                        name: "크레인(타이어)",
                        standard: "10Ton",
                      },
                    },
                    {
                      subtitles: [],
                      machinePriceLaborCost: 0,
                      machinePriceMaterialCost: 0,
                      machinePriceExpenseCost: 0,
                      pcMaterialCost: 0,
                      pcExpenseCost: 0,
                      pcLaborCost: 0,
                      laborCost: 0,
                      expenseCost: 0,
                      materialCost: 2028,
                      labors: [],
                      materials: [],
                      contents: [
                        {
                          name: " ",
                          valueType: "upper-labor",
                          value: 2028,
                          contentValue: "상위 항목 인건비의",
                          percent: 2,
                          type: "재료비",
                        },
                      ],
                      variables: [],
                      id: 9690,
                      name: "기구손료",
                      note: null,
                      standard: null,
                    },
                  ],
                  laborCost: 101436,
                  expenseCost: 5850,
                  materialCost: 58886,
                  totalCost: 166172,
                  id: 1396,
                  name: "가설사무소(조립식)",
                  note: "",
                  standard: "48개월",
                  unit: "㎡",
                  memo: "",
                  code: "",
                  keyword: "",
                },
                name: "가설사무소(조립식)",
                note: "[단산] 제 1호표",
                type: "calculation",
                unit: "㎡",
                origin: true,
                formula: "1",
                standard: "48개월",
                count_value: 1,
                newInChange: true,
                applyPercent: true,
                ready_count: [],
                ready_formula: [],
                materialCost: 0,
                laborCost: 0,
                expenseCost: 0,
              },
            ],
            spread: true,
            cost_total: 763615,
            labor_cost_total: 619583,
            ready_cost_total: [0],
            change_cost_total: 0,
            expense_cost_total: 25820,
            material_cost_total: 118212,
            ready_labor_cost_total: [0],
            change_labor_cost_total: 0,
            ready_expense_cost_total: [0],
            change_expense_cost_total: 0,
            ready_material_cost_total: [0],
            change_material_cost_total: 0,
          },
        ],
        cost_total: 763615,
        corporation: "건축공사",
        labor_cost_total: 619583,
        ready_cost_total: [0],
        change_cost_total: 0,
        expense_cost_total: 25820,
        material_cost_total: 118212,
        ready_labor_cost_total: [0],
        change_labor_cost_total: 0,
        ready_expense_cost_total: [0],
        change_expense_cost_total: 0,
        ready_material_cost_total: [0],
        change_material_cost_total: 0,
      },
    ];
  },
};
