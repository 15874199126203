import React, { useEffect, useRef, useState } from "react";
import { marked } from "marked";
import Typed from "typed.js";

interface MarkdownTyperProps {
  isNew: boolean;
  markdown: string;
  onComplete: (el: HTMLDivElement) => void;
}

const MarkdownTyper: React.FC<MarkdownTyperProps> = ({
  isNew,
  markdown,
  onComplete,
}) => {
  const el = useRef<HTMLDivElement | null>(null);
  const onCompleteRef = useRef(onComplete);

  useEffect(() => {
    onCompleteRef.current = onComplete;
  }, [onComplete]);

  useEffect(() => {
    if (!el.current) return;
    const htmlContent = marked.parse(markdown);
    if (!isNew) {
      el.current.innerHTML = htmlContent;
      onCompleteRef.current(el.current);
      return;
    }
    const typed = new Typed(el.current, {
      strings: [htmlContent as string],
      typeSpeed: 5,
      showCursor: false,
      smartBackspace: true,
      contentType: "html",
      onComplete: () => {
        if (el.current) {
          onCompleteRef.current(el.current);
        }
      },
    });

    return () => {
      typed.destroy();
    };
  }, [markdown, isNew]);

  return <div ref={el} />;
};

export default MarkdownTyper;
