import { PlusOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { pages } from "../../constants/PagePaths";
import { Cookies } from "../../constants/Cookies";
import { util } from "../../config/util";
import AlertContext from "../../contexts/AlertContext";
import { aiApi } from "../../config/aiApi";

const SearchResultAside = () => {
  const [isOpen, setIsOpen] = useState(false);
  const alertContext = useContext(AlertContext);
  const history = useHistory();
  const [historyConversation, setHistoryConversation] = useState([]);
  const getHistoryConversation = async () => {
    if (util.getCookie(Cookies.authKey)) {
      const res = await aiApi.getConversation();
      console.log("res", res);
      setHistoryConversation(res.conversations);
    }
  };
  function formatDate(dateString: string) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}년${month}월${day}일`;
  }

  function typeToText(type: string) {
    if (type === "search") return "기본 웹 검색";
    else if (type === "statement") return "일위대가 자동생성";
    else if (type === "itemized") return "건설 품셈 도우미";
    else return "";
  }
  // conversations 배열을 created_at 기준으로 내림차순 정렬
  const sortedConversations = historyConversation.sort((a: any, b: any) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  const handleClick = (data: { id: string; type: string }) => {
    if (data.type === "search") {
      history.push(`${pages.searchResult}?seq=${data.id}`);
    } else if (data.type === "statement") {
      history.push(`${pages.statementResult}?id=${data.id}`);
    } else if (data.type === "itemized") {
      // history.push(`${pages.itemizedResult}?id=${data.id}`);
    }
  };
  const handleAsideFlag = () => {
    if (util.getCookie(Cookies.authKey)) {
      setIsOpen(!isOpen);
    } else {
      alertContext.showAlert("로그인 후 이용해 주세요.");
    }
  };
  useEffect(() => {
    getHistoryConversation();
  }, []);
  return (
    <div
      className="search-result-aside"
      style={{ left: isOpen ? "0" : "-240px" }}
    >
      <div
        className="aside-flag"
        onClick={() => handleAsideFlag()}
        style={{ left: isOpen ? "calc(100% - 48px)" : "100%" }}
      >
        {isOpen ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M43.043 7.99996L43.043 40.0006L40.043 40.0006L40.043 7.99996L43.043 7.99996Z"
              fill="var(--color-primary)"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.97867 25.5006L20.4179 35.9398L18.2966 38.0612L6.52506 26.2896C5.15822 24.9228 5.15822 22.7067 6.52506 21.3399L18.2966 9.56837L20.4179 11.6897L9.60697 22.5006L34.543 22.5006L34.543 25.5006L9.97867 25.5006ZM8.54297 23.6115L8.54297 24.018C8.48558 23.889 8.48558 23.7406 8.54297 23.6115Z"
              fill="var(--color-primary)"
            ></path>
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 40.0006V8H8.5V40.0006H5.5Z"
              fill="var(--color-primary)"
            ></path>
            <path
              d="M38.9359 25.5L28.1251 36.3108L30.2465 38.4321L42.018 26.6606C43.3848 25.2938 43.3848 23.0777 42.018 21.7109L30.2465 9.93933L28.1251 12.0607L38.5645 22.5L14 22.5V25.5L38.9359 25.5Z"
              fill="var(--color-primary)"
            ></path>
          </svg>
        )}
      </div>
      <div className="search-result-aside-content">
        <div
          className="search-result-aside-content-item-primary"
          onClick={() => history.push(pages.search)}
        >
          <PlusOutlined />
          <span>새 질문</span>
        </div>
        <div className="search-result-aside-content-item">
          <span className="search-result-aside-content-title">최근 검색</span>
        </div>
        <div className="search-result-aside-content-history-list">
          {sortedConversations.map((conversation: any, index: number) => (
            <div
              className="search-result-aside-content-history"
              onClick={() =>
                handleClick({
                  id: conversation.conversation_id,
                  type: conversation.type,
                })
              }
              key={index}
            >
              <span className="search-result-aside-content-date">
                {formatDate(conversation.created_at)}
              </span>
              <span className="search-result-aside-content-name">
                {conversation.title}
              </span>
              <span className="search-result-aside-content-type">
                {typeToText(conversation.type)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchResultAside;
